import React from 'react'
import { Button } from 'semantic-ui-react'
import { IconChevronRight } from '@tabler/icons-react'
import classNames from 'classnames'

import './InboxAccordion.scss'

export const InboxAccordion = ({
  expanded = true,
  handleToggle,
  title,
  id,
  children,
  actions,
  muted,
}) => {
  return (
    <div className="inbox-accordion" data-testid={`inbox-accordion-${id}`}>
      <header>
        <div className="flex-align-center gap">
          <Button
            basic
            icon
            compact
            className="svg-button borderless"
            data-testid={`inbox-accordion-button-${id}`}
            onClick={() => handleToggle(id)}
          >
            <IconChevronRight
              className={classNames('icon-accordion', { 'icon-accordion--expanded': expanded })}
            />
            <h2 className={classNames({ 'muted-text': muted })}>{title}</h2>
          </Button>
        </div>
        <div className="flex-align-center small-gap">{actions}</div>
      </header>
      {expanded && <section data-testid={`inbox-content-${id}`}>{children}</section>}
    </div>
  )
}
