import React from 'react'
import { Button, Dropdown, Radio } from 'semantic-ui-react'
import classNames from 'classnames'

import './TranscriptHeader.scss'

export const TranscriptHeader = ({
  searchQuery,
  handleSearchTranscript,
  handleAllowAutoScrollChange,
  showTranscriptSelector,
  allowAutoScroll,
  transcriptSelectorOptions,
  handleTranscriptSelectionChange,
  transcriptType,
  enableAutoScroll,
  connected = false,
}) => {
  return (
    <header
      className={classNames('transcript-header', {
        'transcript-header-connected': connected,
        'call-window-scrollable__headers': !connected,
      })}
    >
      <h3>Transcript</h3>
      <div className="transcript-options-container">
        <div className="transcript-search">
          <input
            type="text"
            placeholder="Search transcript..."
            value={searchQuery}
            onChange={handleSearchTranscript}
            className="transcript-search-input"
            data-testid="transcript-search"
          />
        </div>
        {enableAutoScroll && (
          <Radio
            toggle
            data-testid="auto-scroll-toggle"
            checked={allowAutoScroll}
            onChange={handleAllowAutoScrollChange}
            label="Auto-Scroll"
          />
        )}
        {showTranscriptSelector && (
          <Dropdown
            icon={null}
            trigger={
              <Button basic icon="ellipsis vertical" data-testid="transcript-selector" compact />
            }
            floating
            direction="right"
            onChange={handleTranscriptSelectionChange}
            clearable={false}
            value={transcriptType}
            options={transcriptSelectorOptions}
          />
        )}
      </div>
    </header>
  )
}
