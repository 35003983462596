import React from 'react'
import { Loader, Button } from 'semantic-ui-react'
import { IconX } from '@tabler/icons-react'
import { isEmpty } from 'lodash'

import { SKIP_MODIFIER } from '@/utils/constants'

import { VideoPlayerToggleButton } from './components/VideoPlayerToggleButton'
import { SkipButton } from './components/SkipButton'
import { PlayPauseButton } from './components/PlayPauseButton'
import { PlaybackRateDropdown } from './components/PlaybackRateDropdown'
import { AudioTrackTime } from './components/AudioTrackTime'
import { AudioTrackProgress } from './components/AudioTrackProgress'
import { VolumeSlider } from './components/VolumeSlider'
import { useAudioPlayer } from './AudioPlayerContext'
import { useTranscript } from '../transcript/TranscriptContext'

import './AudioPlayer.scss'

export const AudioPlayer = ({
  loading,
  autoFocus = false,
  isPlaying,
  progress,
  duration,
  playbackRate,
  volume,
  audioUrl,
  isDisabled,
  handleScrub,
  handleBackSkip,
  handleForwardSkip,
  handleVolumeChange,
  handlePlaybackRateChange,
  handleCloseDrawer,
  togglePlayState,
  hideAudioTrack,
  hidePlaybackRate,
  hideVolume,
  hideCloseButton,
  isVideoAvailable = false,
  isVideoVisible = false,
  isTranscriptAvailable = false,
  toggleVideoVisibility,
}) => {
  const { audioRef } = useAudioPlayer()
  const { transcriptToDisplay, isTranscriptVisible, handleToggleTranscriptVisibility } =
    useTranscript()
  const showMoreOptions =
    !hidePlaybackRate ||
    !hideCloseButton ||
    isVideoAvailable ||
    (isTranscriptAvailable && !isEmpty(transcriptToDisplay))

  return (
    <>
      <audio src={audioUrl} ref={audioRef} />
      <div className="audio-player" data-testid="audio-player">
        {loading ? (
          <Loader inline active />
        ) : (
          <>
            <div className="audio-player-controls">
              <PlayPauseButton
                isPlaying={isPlaying}
                togglePlayState={togglePlayState}
                isDisabled={isDisabled}
                autoFocus={autoFocus}
              />
              <div className="flex-align-center small-gap">
                <SkipButton
                  modifier={SKIP_MODIFIER}
                  handleSkip={handleBackSkip}
                  isDisabled={isDisabled}
                />
                <SkipButton
                  modifier={SKIP_MODIFIER}
                  handleSkip={handleForwardSkip}
                  isForwardSkip
                  isDisabled={isDisabled}
                />
              </div>
              {!hideVolume && (
                <VolumeSlider
                  volume={volume}
                  handleVolumeChange={handleVolumeChange}
                  isDisabled={isDisabled}
                />
              )}
            </div>
            <AudioTrackTime duration={duration} progress={progress} divider />
            {!hideAudioTrack && (
              <div className="audio-track-progress">
                <AudioTrackProgress
                  isDisabled={isDisabled}
                  duration={duration}
                  progress={progress}
                  handleScrub={handleScrub}
                />
              </div>
            )}
            {showMoreOptions && (
              <div className="flex-align-center small-gap">
                {isVideoAvailable && (
                  <VideoPlayerToggleButton
                    isVideoVisible={isVideoVisible}
                    toggleVideoVisibility={toggleVideoVisibility}
                  />
                )}
                {!hidePlaybackRate && (
                  <PlaybackRateDropdown
                    isDisabled={isDisabled}
                    handlePlaybackRateChange={handlePlaybackRateChange}
                    playbackRate={playbackRate}
                  />
                )}
                {!hideCloseButton && (
                  <Button
                    compact
                    icon
                    basic
                    type="button"
                    className="svg-button"
                    onClick={handleCloseDrawer}
                  >
                    <IconX />
                  </Button>
                )}
                {isTranscriptAvailable && !isEmpty(transcriptToDisplay) && (
                  <Button
                    compact
                    icon
                    secondary
                    type="button"
                    className="svg-button"
                    onClick={handleToggleTranscriptVisibility}
                  >
                    {isTranscriptVisible ? 'Hide Transcript' : 'Show Transcript'}
                  </Button>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  )
}
