import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from 'semantic-ui-react'
import { isEmpty, startCase, isArray, isObject } from 'lodash'

import { Pill } from '@/components/pills/Pill'
import { fetchCallSummaryByCallId } from '@/reducers/callSearch/callSearch.actions'
import { IconSparkles } from '@tabler/icons-react'

export const Summary = ({ callId }) => {
  const dispatch = useDispatch()
  const {
    callExplorer: { callSummary },
    loading: { qaScores: callSummaryLoading },
  } = useSelector((state) => state.callSearch)

  const labelColors = {
    reason: 'var(--blurple-500)',
    objections: 'var(--red-600)',
    'next steps': 'var(--green-400)',
    outcome: 'var(--violet-300)',
  }

  const doCallSummariesExist = (callSummary) => {
    if (isEmpty(callSummary.parsed_summary)) {
      return false
    }

    return Object.keys(callSummary.parsed_summary).some((key) => callSummary.parsed_summary[key])
  }

  const formatArrayContent = (arrayContent) => (
    <ul className="summary-list-content">
      {arrayContent.map((item) => (
        <li key={`list-item-${item}`}>{item}</li>
      ))}
    </ul>
  )

  const formatNestedContent = (nestedContent) => (
    <ul className="summary-list-content">
      {Object.keys(nestedContent).map((key) => (
        <li key={`list-item-${key}`}>{`${startCase(key)}: ${nestedContent[key]}`}</li>
      ))}
    </ul>
  )

  const formatContent = (content) => {
    if (isArray(content)) {
      return formatArrayContent(content)
    }
    if (isObject(content)) {
      return formatNestedContent(content)
    }

    return <p>{content}</p>
  }

  useEffect(() => {
    dispatch(fetchCallSummaryByCallId(callId))
  }, [])

  return (
    <div className="call-metadata-container">
      <h2 className="flex-align-center small-gap">
        Summary
        <Pill small magical icon={<IconSparkles />}>
          AI
        </Pill>
      </h2>

      {doCallSummariesExist(callSummary) && formatContent(callSummary.parsed_summary.summary)}
      {callSummaryLoading && (
        <div className="empty-table">
          <Loader inline active />
        </div>
      )}
      {!callSummaryLoading && doCallSummariesExist(callSummary) && (
        <div className="call-metadata-wrapper">
          <div className="call-metadata-summary-container expanded">
            {Object.keys(callSummary.parsed_summary)
              .filter((key) => key !== 'summary')
              .map((key) => {
                const { parsed_summary_v2, parsed_summary } = callSummary
                const content = isEmpty(parsed_summary_v2)
                  ? parsed_summary[key]
                  : parsed_summary_v2[key]

                return (
                  <div className="summary-item" key={key}>
                    <h3>
                      <div
                        className="summary-color"
                        style={{ background: labelColors[key] || 'var(--dark-knight-300)' }}
                      />
                      <span>{startCase(key)}</span>
                    </h3>
                    {formatContent(content)}
                  </div>
                )
              })}
          </div>
        </div>
      )}
      {!callSummaryLoading && !doCallSummariesExist(callSummary) && (
        <p>No call summary associated with this call</p>
      )}
    </div>
  )
}
