import React from 'react'
import classNames from 'classnames'

import { ConversationBlock } from './ConversationBlock'
import { SilenceBlock } from './SilenceBlock'
import { CONVERSATION, SILENCE } from './transcript.constants'

import './TranscriptBlocks.scss'

export const TranscriptBlocks = ({
  transcript = [],
  transcriptBlockRef = { current: null },
  closestTranscriptIndexRef = { current: null },
  showTranscriptHypothesis,
  handleTimestampClick,
  searchQuery,
  hardSelectedEvent,
  metadata,
  connected = false,
}) => {
  return (
    <div
      className={classNames('transcript-blocks', { 'transcript-blocks-connected': connected })}
      data-testid="transcript-blocks"
      ref={transcriptBlockRef}
    >
      {transcript.map((section, index) => {
        const { type, side, data, timestamp, duration } = section

        if (type === CONVERSATION) {
          return (
            <div
              key={`clickable-conversation-${data[0].timestamp}`}
              className="transcript-scroll-top"
              onClick={() => {
                if (handleTimestampClick) {
                  handleTimestampClick(data[0].timestamp)
                }
              }}
            >
              <ConversationBlock
                key={`conversation-${data[0].timestamp}`}
                side={side}
                data={data}
                showHypothesis={showTranscriptHypothesis}
                highlighted={
                  Boolean(handleTimestampClick) && closestTranscriptIndexRef.current === index
                }
                searchQuery={searchQuery}
                hardSelectedEvent={hardSelectedEvent}
                metadata={metadata}
              />
            </div>
          )
        }

        if (type === SILENCE) {
          return (
            <SilenceBlock
              key={`silence-${timestamp}`}
              duration={duration}
              highlighted={closestTranscriptIndexRef.current === index}
            />
          )
        }

        return null
      })}
    </div>
  )
}
