import React, { useState } from 'react'
import { Dropdown } from 'semantic-ui-react'
import { IconUserCircle } from '@tabler/icons-react'
import { isEmpty } from 'lodash'

import { BasicModal } from '@/components/layout/modals/BasicModal'
import { OrgHierarchySelector } from '@/views/OrgHierarchy/OrgHierarchySelector'
import { MoreActionsIcon } from '@/components/icons/MoreActionsIcon'

export const SidebarFooter = ({ currentUser, userOrgHierarchy, handleLogout }) => {
  const [showHierarchyModal, setShowHierarchyModal] = useState(false)
  const userFullName = `${currentUser.first_name} ${currentUser.last_name}`
  const isHierarchyManager = currentUser.hierarchy_manager

  const handleSwitchOrganization = () => {
    setShowHierarchyModal(true)
  }

  return (
    <>
      <footer className="balto-sidebar__footer">
        <div className="flex-align-center medium-gap">
          <IconUserCircle />
          <div className="text-overflow">{userFullName}</div>
        </div>
        <div>
          <Dropdown
            item
            direction="left"
            icon={<MoreActionsIcon />}
            button
            className="sidebar-more-actions-button icon secondary compact svg-button"
            data-testid="sidebar-more-actions-button"
          >
            <Dropdown.Menu>
              {!isEmpty(userOrgHierarchy) && isHierarchyManager && (
                <Dropdown.Item
                  data-testid="org-hierarchy-button"
                  onClick={handleSwitchOrganization}
                >
                  Switch Organization
                </Dropdown.Item>
              )}
              <Dropdown.Item data-testid="sign-out-button" onClick={handleLogout}>
                Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </footer>
      <BasicModal
        title="Switch Organization"
        onClose={() => setShowHierarchyModal(false)}
        show={showHierarchyModal}
        size="tiny"
      >
        <OrgHierarchySelector
          currentUserOrgId={currentUser.organizationid}
          userOrgHierarchy={userOrgHierarchy}
        />
      </BasicModal>
    </>
  )
}
