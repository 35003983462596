import React from 'react'
import { Button } from 'semantic-ui-react'

export const TaskDrawerFooter = ({
  handleClose,
  primaryActionButtonText = 'Mark as Reviewed',
  primaryButtonDisabled,
  handlePrimaryAction,
  secondaryActionButtonText = 'Mark as Ignored',
  handleSecondaryAction,
}) => {
  return (
    <div className="drawer-footer">
      <div className="flex-align-center flex-space-between medium-gap">
        <div className="flex-align-center medium-gap">
          <Button basic onClick={handleClose} data-testid="drawer-close">
            Close
          </Button>
        </div>
        <div className="flex-align-center medium-gap">
          {handleSecondaryAction && (
            <Button
              type="button"
              secondary
              onClick={handleSecondaryAction}
              data-testid="drawer-secondary-action"
            >
              {secondaryActionButtonText}
            </Button>
          )}
          {handlePrimaryAction && (
            <Button
              type="button"
              primary
              onClick={handlePrimaryAction}
              data-testid="drawer-primary-action"
              disabled={primaryButtonDisabled}
            >
              {primaryActionButtonText}
            </Button>
          )}
        </div>
      </div>
    </div>
  )
}
