import React, { useState } from 'react'
import { isEmpty } from 'lodash'
import { Loader, Button, TextArea, Form } from 'semantic-ui-react'

import { Comment } from './Comment'

import './Comments.scss'

export const Comments = ({
  associatedId,
  comments,
  addComment,
  updateComment,
  showHeader = true,
  showLabel = true,
  commentType = 'Comment',
  placeholder = 'Leave a comment...',
  commentProperty = 'comment',
  loading,
}) => {
  const [newCommentText, setNewCommentText] = useState('')
  const saveCommentLavel = `Add ${commentType}`

  return (
    <div className="comments-container">
      {showHeader && (
        <h2 className="comment-header" data-testid="comment-header">
          {commentType}
        </h2>
      )}
      {showLabel && <h3>New {commentType}</h3>}

      <Form className="comment-form">
        <TextArea
          className="mb"
          data-testid="comment-textarea"
          placeholder={placeholder}
          value={newCommentText}
          onKeyDown={(event) => {
            event.stopPropagation()
          }}
          onChange={(event) => {
            setNewCommentText(event.target.value)
          }}
        />
        <div className="flex-end">
          <Button
            primary
            data-testid="comment-save"
            type="submit"
            onClick={() => {
              addComment(newCommentText, associatedId)
              setNewCommentText('')
            }}
            disabled={isEmpty(newCommentText)}
          >
            {saveCommentLavel}
          </Button>
        </div>
      </Form>
      {loading ? (
        <Loader active />
      ) : (
        comments.map((comment) => (
          <Comment
            key={comment.id}
            commentData={comment}
            associatedId={associatedId}
            updateComment={updateComment}
            commentType={commentType}
            commentProperty={commentProperty}
          />
        ))
      )}
    </div>
  )
}
