import React, { useState, useMemo, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Button, Popup, Segment } from 'semantic-ui-react'
import { isEmpty, truncate, isEqual, toArray } from 'lodash'
import pluralize from 'pluralize'
import {
  IconCircleCheckFilled,
  IconCircleDashedCheck,
  IconTrash,
  IconReplace,
} from '@tabler/icons-react'

import {
  deletePlaylistCall,
  fetchPlaylist,
  patchPlaylist,
  patchPlaylistCall,
  fetchSummariesForCallIds,
} from '@/reducers/playlists/playlists.actions'
import { pauseMediaPlayer } from '@/reducers/media/media.redux'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { formatTime, reorder } from '@/utils/helpers'
import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'
import ConfirmForm from '@/components/forms/ConfirmForm'
import { MediaDrawer } from '@/components/media/MediaDrawer'
import { Drawer } from '@/components/drawer/Drawer'
import NoData from '@/components/NoData'
import { PLAYLISTS_TABLE_NAME } from '@/reducers/customColumns/customColumns.constants'
import { DEFAULT_CALL_COLUMNS } from '@/views/Calls/callColumns'
import {
  isSummaryColumnHidden,
  getMemoizedColumns,
  getDynamicColumns,
  getCompleteColumns,
} from '@/reducers/customColumns/customColumns.helpers'
import { auditColumns } from '@/utils/constants'
import { CustomColumns } from '@/components/tables/CustomColumns/CustomColumns'
import { Breadcrumbs } from '@/components/forms/Breadcrumbs/Breadcrumbs'
import { CommentEditorContainer } from './CommentEditorContainer'

import { PlaylistName } from './PlaylistName'
import { EditPlaylistCallAlias } from './EditPlaylistCallAlias'

export const PlaylistCallsContent = ({ isCoachingPage = false }) => {
  const dispatch = useDispatch()
  const [rowIdsRendered, setRowIdsRendered] = useState([])
  const { playlist, playlistChanges, loading } = useSelector((state) => state.playlists)
  const { customColumns } = useSelector((state) => state.customColumns)
  const tableIndex = 'call_id'
  const savedCustomColumns = customColumns[PLAYLISTS_TABLE_NAME]
  const summaryColumnHidden = isSummaryColumnHidden(savedCustomColumns)

  const updateRowIdsRendered = (rowIds) => {
    if (!isEqual(rowIds, rowIdsRendered)) {
      setRowIdsRendered(rowIds)
    }
  }

  const handleReorder = async (result, activePage, rowsPerPage) => {
    const { source, destination } = result
    if (!destination) return

    const numberOfRowsToOffset = activePage * rowsPerPage - rowsPerPage
    const offset = numberOfRowsToOffset

    let reorderedCalls = []
    if (activePage === 1) {
      reorderedCalls = reorder(playlist.playlist_calls, source.index, destination.index)
    } else {
      reorderedCalls = reorder(
        playlist.playlist_calls,
        offset + source.index,
        offset + destination.index
      )
    }

    const changedCalls = reorderedCalls
      .map((call, index) => {
        if (call.order_id !== index) {
          return {
            call_id: call.call_id,
            is_flagged: call.is_flagged,
            order_id: index,
          }
        }
        return null
      })
      .filter(Boolean)

    if (!isEmpty(changedCalls)) {
      await dispatch(
        patchPlaylist(playlist.uuid, playlist.organization_id, { playlist_calls: changedCalls })
      )
      await dispatch(fetchPlaylist(playlist.uuid, playlist.organization_id))
    }
  }

  const handleRemoveCallFromPlaylist = (callId) => {
    dispatch(deletePlaylistCall(playlist.uuid, playlist.organization_id, callId))
  }

  const handleToggleFlagged = (row) => {
    dispatch(
      patchPlaylistCall(
        playlist.uuid,
        playlist.organization_id,
        row.call_id,
        { is_flagged: !row.is_flagged },
        true
      )
    )
  }

  const handleEditAlias = (value, call) => {
    dispatch(
      patchPlaylistCall(
        playlist.uuid,
        playlist.organization_id,
        call.call_id,
        { alias: value },
        true
      )
    )
  }

  const pauseAudio = () => {
    dispatch(pauseMediaPlayer())
  }

  const { callId: callIdListeningTo, progress } = useSelector((state) => state.media.mediaPlayer)

  const generateCallExplorerLink = (callId) => {
    return `/call-explorer/${callId}?playlistUuid=${playlist.uuid}${callId === callIdListeningTo ? `&timestamp=${progress}&autoplay=true` : ''}`
  }

  const trimText = (text) => {
    return truncate(text, { length: 50, omission: '...' })
  }

  const buildChangeString = (change) => {
    if (!isEmpty(change.calls_added)) {
      return `Added: ${change.calls_added.join(', ')}`
    }
    if (!isEmpty(change.calls_removed)) {
      return `Removed: ${change.calls_removed.join(', ')}`
    }
    if (!isEmpty(change.name)) {
      return `Renamed this to: ${change.name}`
    }
    if (!isEmpty(change.calls_updated)) {
      return change.calls_updated.map((obj) => {
        const [key, value] = Object.entries(obj)[0]

        let valueStr = value
        if (value === 'Flagged') {
          valueStr = 'Reviewed'
        }
        if (value === 'Removed flag') {
          valueStr = 'Removed review'
        }
        return `${valueStr}: ${key}`
      })
    }
    return ''
  }

  const playlistChangesRows = playlistChanges?.map((change) => {
    const displayChanges = buildChangeString(change)

    const eventType =
      change.event_type === 'update_playlist_calls' ? 'Update Calls' : 'Update Playlist'

    return {
      ...change,
      created_at: formatTime(change.created_at),
      event_type: eventType,
      what_changed: {
        as: (
          <div>
            <Popup
              disabled={displayChanges.length < 50}
              inverted
              content={displayChanges}
              trigger={<div>{trimText(displayChanges)}</div>}
            />
          </div>
        ),
        value: displayChanges,
      },
      calls_added: !isEmpty(change.calls_added) ? change.calls_added.join(', ') : 'N/A',
      calls_removed: !isEmpty(change.calls_removed) ? change.calls_removed.join(', ') : 'N/A',
      calls_updated: !isEmpty(change.calls_updated) ? change.calls_updated.join(', ') : 'N/A',
    }
  })

  const actions = [
    {
      label: 'Delete',
      trigger: (row) => {
        return (
          <ButtonAndFormModal
            popup
            icon={<IconTrash />}
            buttonProps={{ basic: true, compact: true }}
            popupProps={{ position: 'top right' }}
            modalId={`playlists/delete/call/${row.call_id}`}
            modalTitle="Remove Call"
            modalProps={{ size: 'tiny' }}
            form={
              <ConfirmForm
                valueToSubmit={row.call_id}
                bodyText="Are you sure you want to remove this call? This action cannot be undone."
                danger
                primaryButtonText="Remove Call"
                handleSubmit={() => handleRemoveCallFromPlaylist(row.call_id)}
              />
            }
          />
        )
      },
    },
  ]

  const DEFAULT_COLUMNS = [
    {
      accessor: 'call_identifier',
      label: 'Call Identifier',
      isSortable: true,
      is_locked: true,
      sticky: true,
      isSearchable: true,
    },
    {
      accessor: 'is_flagged',
      label: 'Status',
      isSortable: false,
      is_locked: true,
      isSearchable: true,
      searchSchema: {
        label: 'Coaching Status',
        type: 'select',
        options: [
          { label: 'Reviewed', value: true },
          { label: 'Not Reviewed', value: false },
        ],
      },
    },
    {
      accessor: 'comments',
      label: isCoachingPage ? 'Coaching Notes' : 'Notes',
      isSortable: false,
    },
    ...DEFAULT_CALL_COLUMNS.filter((column) => column.accessor !== 'start_time'),
    ...DEFAULT_CALL_COLUMNS.filter((column) => column.accessor === 'start_time').map((column) => ({
      ...column,
      isSearchable: true,
      searchSchema: { label: 'Date', type: 'date' },
    })),
  ]

  const columns = useMemo(
    () => getMemoizedColumns(savedCustomColumns, DEFAULT_COLUMNS),
    [savedCustomColumns]
  )
  const dynamicColumns = useMemo(
    () => getDynamicColumns(summaryColumnHidden, playlist?.playlist_calls),
    [columns, playlist?.playlist_calls, rowIdsRendered, savedCustomColumns, summaryColumnHidden]
  )
  const completeColumns = getCompleteColumns(columns, dynamicColumns)

  useEffect(() => {
    if (!isEmpty(rowIdsRendered) && !summaryColumnHidden) {
      const callsRenderedWithoutSummariesLoaded = toArray(playlist?.playlist_calls)
        .filter((call) => rowIdsRendered.includes(call.call_id)) // Only fetches summaries for calls that have been rendered
        .filter(({ call_summary }) => call_summary === undefined)
        .map(({ call_id }) => call_id)
      if (!isEmpty(callsRenderedWithoutSummariesLoaded)) {
        dispatch(fetchSummariesForCallIds(callsRenderedWithoutSummariesLoaded))
      }
    }
  }, [rowIdsRendered, savedCustomColumns, playlist?.playlist_calls, summaryColumnHidden])

  const renderPlaylistPage = () => {
    if (isEmpty(playlist)) {
      return (
        <>
          <header className="data-grid-header page-header" data-testid="playlist-page-no-data">
            <div className="flex-align-center medium-gap">
              <h1>{isCoachingPage ? 'Coaching' : 'Playlist'}</h1>
            </div>
          </header>
          <div className="empty-table wide">
            <NoData />
          </div>
        </>
      )
    }

    const rows = playlist?.playlist_calls?.map((call) => ({
      ...call,
      call_identifier: {
        cellProps: {
          className: 'collapsing',
        },
        value: call.alias || call.call_id,
        as: (
          <EditPlaylistCallAlias
            key={call.call_id}
            playlistUuid={playlist.uuid}
            call={call}
            handleEditAlias={handleEditAlias}
            generateCallExplorerLink={generateCallExplorerLink}
            pauseAudio={pauseAudio}
          />
        ),
      },
      is_flagged: {
        cellProps: {
          className: 'collapsing compact-cell',
        },
        value: call.is_flagged,
        as: (
          <Popup
            inverted
            content={call.is_flagged ? 'Remove Reviewed Status' : 'Mark as Reviewed'}
            trigger={
              <Button
                icon
                basic
                compact
                className="svg-button"
                onClick={() => handleToggleFlagged(call)}
              >
                {call.is_flagged ? (
                  <IconCircleCheckFilled className="icon-svg-large status-success" />
                ) : (
                  <IconCircleDashedCheck className="icon-svg-large" />
                )}
              </Button>
            }
          />
        ),
      },
      comments: {
        value: call.callComments,
        as: (
          <Drawer
            title={`Comments: ${call.alias || call.call_id}`}
            trigger={
              <a>
                {!isEmpty(call.callComments)
                  ? `View ${pluralize('Comment', call.callComments.length, true)}`
                  : 'Leave a Comment'}
              </a>
            }
          >
            <CommentEditorContainer callId={call.call_id} />
          </Drawer>
        ),
      },
    }))

    return (
      <>
        <header
          className="data-grid-header page-header"
          data-testid={isCoachingPage ? 'coaching-page' : 'playlist-page'}
        >
          {isCoachingPage ? (
            <h1>Coaching</h1>
          ) : (
            <div className="flex-align-center medium-gap">
              <Breadcrumbs
                backLinks={[
                  { label: 'Playlists', link: '/playlists', dataTestId: 'breadcrumb-playlists' },
                ]}
                currentLink={
                  <PlaylistName
                    loading={loading.playlist}
                    playlist={playlist}
                    uuid={playlist.uuid}
                  />
                }
              />
            </div>
          )}
          <div className="flex-align-center medium-gap">
            <CustomColumns tableName={PLAYLISTS_TABLE_NAME} defaultColumns={DEFAULT_COLUMNS} />
            {!isEmpty(playlist?.playlist_calls) && !isEmpty(playlistChangesRows) && (
              <ButtonAndFormModal
                buttonLabel="Change History"
                icon={<IconReplace />}
                buttonProps={{ secondary: true }}
                modalId={`playlists/audit/${playlist.uuid}`}
                modalProps={{ size: 'large' }}
                modalTitle="Change History"
                modalContent={
                  <Segment className="not-padded">
                    <AdvancedTable
                      loading={loading.playlist}
                      rows={playlistChangesRows}
                      columns={auditColumns}
                      pagination
                      defaultOrder="desc"
                      sortEnabled={false}
                    />
                  </Segment>
                }
              />
            )}
          </div>
        </header>

        <AdvancedTable
          noDataHeader={
            isCoachingPage ? 'No Calls Available for Coaching' : 'No Calls in this Playlist'
          }
          noDataText={
            isCoachingPage
              ? 'Flag calls for coaching and they will appear on this page.'
              : 'Begin by adding calls to your new playlist.'
          }
          noDataAction={
            <Link to="/call-explorer" className="ui button primary">
              Explore Calls
            </Link>
          }
          dataGrid
          index={tableIndex}
          loading={loading.playlist}
          rows={rows}
          columns={completeColumns}
          actions={actions}
          stickyAction
          pagination
          reorderable={!isCoachingPage}
          handleReorder={!isCoachingPage && handleReorder}
          striped={false}
          rowsPerPage={30}
          updateRowIdsRendered={updateRowIdsRendered}
        />

        <MediaDrawer />
      </>
    )
  }

  return renderPlaylistPage()
}
