import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from 'semantic-ui-react'
import { isEmpty, startCase } from 'lodash'

import {
  COACHING_TASK_TYPE,
  QA_DISPUTE_TASK_TYPE,
  COMPLIANCE_TASK_TYPE,
  QA_MANUAL_SCORE_TASK_TYPE,
} from '@/reducers/tasks/tasks.constants'
import {
  fetchTaskByCriteriaScoreIds,
  fetchTaskByCallEvidence,
} from '@/reducers/tasks/tasks.actions'
import { setTask } from '@/reducers/tasks/tasks.redux'
import { resetMediaPlayer } from '@/reducers/media/media.redux'

import { Metadata } from './Metadata'
import { TaskComments } from '../components/TaskComments'
import { CallEvidence } from '../components/CallEvidence'
import { DisputeDrawer } from './DisputeDrawer'
import { ManualScoreDrawer } from './ManualScoreDrawer'
import { TaskDrawerFooter } from './TaskDrawerFooter'

import './TaskDrawer.scss'

// TaskDrawer uses partialTask to refer to the partial task that's returned from the GET /tasks API
// We fetch more data to get the complete task object using the score_evidence property
// partialTask will always be present, and task is completed after an API call for the call audio, critera data, etc.
export const TaskDrawer = ({
  partialTask = {},
  commentsEnabled = false,
  handleClose,
  handlePrimaryAction,
  handleSecondaryAction,
}) => {
  const dispatch = useDispatch()
  const { loading, task } = useSelector((state) => state.tasks)
  const agent = `${partialTask.agent_first_name} ${partialTask.agent_last_name}`

  const primaryAction = () => handlePrimaryAction(partialTask.id, handleClose)
  const secondaryAction = () => handleSecondaryAction(partialTask.id, handleClose)

  const getTaskDrawerContent = (taskType) => {
    switch (taskType) {
      case QA_DISPUTE_TASK_TYPE:
        return (
          <DisputeDrawer
            handlePrimaryAction={primaryAction}
            handleSecondaryAction={secondaryAction}
            handleClose={handleClose}
            agent={agent}
            task={task}
          />
        )
      case QA_MANUAL_SCORE_TASK_TYPE:
        return (
          <ManualScoreDrawer
            handlePrimaryAction={primaryAction}
            handleSecondaryAction={secondaryAction}
            handleClose={handleClose}
            agent={agent}
            task={task}
          />
        )
      default:
        return (
          <TaskDrawerFooter
            handleClose={handleClose}
            handlePrimaryAction={primaryAction}
            handleSecondaryAction={secondaryAction}
          />
        )
    }
  }

  const getSubheader = (taskType) => {
    switch (taskType) {
      case COACHING_TASK_TYPE:
        return 'Coaching Opportunity'
      case QA_DISPUTE_TASK_TYPE:
      case QA_MANUAL_SCORE_TASK_TYPE:
        return 'Call & Quick Links'
      case COMPLIANCE_TASK_TYPE:
        return 'Escalation Reason'
      default:
        return null
    }
  }

  useEffect(() => {
    // Score exists
    if (!isEmpty(partialTask.score_evidence)) {
      const criteriaScoreIds = partialTask.score_evidence.map(
        (evidence) => evidence.scorecard_criteria_score_id
      )
      dispatch(fetchTaskByCriteriaScoreIds(criteriaScoreIds, partialTask))
    }

    // Call exists
    else if (!isEmpty(partialTask.call_evidence)) {
      dispatch(fetchTaskByCallEvidence(partialTask))
    }

    return () => {
      // Clear task to prevent breaking between loading tasks
      dispatch(setTask(null))
      // Make sure any audio in the drawer gets reset
      dispatch(resetMediaPlayer())
    }
  }, [partialTask])

  if (isEmpty(partialTask)) {
    return null
  }

  if (loading.task) {
    return (
      <div className="task-drawer">
        <Loader active />
      </div>
    )
  }

  return (
    <div className="task-drawer" data-testid="task-drawer">
      <h2>{partialTask.name}</h2>
      <Metadata
        agent={agent}
        createdAt={partialTask.created_at}
        scoreEvidence={partialTask.score_evidence}
        type={partialTask.type}
        status={partialTask.status}
      />
      {!isEmpty(task) && (
        <div className="task-drawer__content">
          <h3>{getSubheader(partialTask.type)}</h3>
          {!isEmpty(task.score_evidence) && <CallEvidence evidence={task.score_evidence} />}
          {!isEmpty(task.call_evidence) && <CallEvidence evidence={task.call_evidence} />}
          {getTaskDrawerContent(partialTask.type)}
        </div>
      )}
      {commentsEnabled && (
        <div className="task-drawer__comments">
          <h3>{startCase(partialTask.type)} Notes</h3>
          <TaskComments
            taskId={partialTask.id}
            commentType={`${startCase(partialTask.type)} Note`}
            commentProperty="note_text"
          />
        </div>
      )}
    </div>
  )
}
