import React from 'react'
import { Popup, Dropdown } from 'semantic-ui-react'

export const PLAYBACK_RATE_OPTIONS = [
  { key: 0.5, text: '0.5x', value: 0.5 },
  { key: 0.75, text: '0.75x', value: 0.75 },
  { key: 1, text: '1x', value: 1 },
  { key: 1.25, text: '1.25x', value: 1.25 },
  { key: 1.5, text: '1.5x', value: 1.5 },
  { key: 2, text: '2x', value: 2 },
]

export const PlaybackRateDropdown = ({ isDisabled, handlePlaybackRateChange, playbackRate }) => {
  return (
    <Popup
      inverted
      content="Change Playback Rate"
      trigger={
        <Dropdown
          floating
          direction="right"
          icon="angle down"
          button
          className="icon secondary svg-button compact"
          header="Playback Rate"
          data-testid="playback-speed-dropdown"
          onChange={handlePlaybackRateChange}
          clearable={false}
          value={playbackRate}
          options={PLAYBACK_RATE_OPTIONS}
          disabled={isDisabled}
        />
      }
    />
  )
}
