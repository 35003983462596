import { toast } from 'react-toastify'
import queryString from 'query-string'
import { cloneDeep } from 'lodash'

import { fetchingAPI, apiService } from '@/api'
import { fetchAudioForCallId, fetchVideoForCallId } from '@/reducers/media/media.actions'

import * as redux from './tasks.redux'
import * as TASK_TYPES from './tasks.constants'
import { fetchCall } from '../callSearch/callSearch.actions'

export const fetchTasks = (taskType) => async (dispatch) => {
  try {
    const params = queryString.stringify({
      task_types: taskType,
      task_statuses: ['completed', 'expired', 'ignored', 'ready_for_review'],
    })

    dispatch(redux.setLoading({ [TASK_TYPES.INBOX]: true }))
    const response = await fetchingAPI(`${apiService.workflow}/tasks?${params}`, 'GET', dispatch)

    dispatch(redux.setTasks(response))
  } catch (err) {
    toast.error('Failed to fetch tasks')
  } finally {
    dispatch(redux.setLoading({ [TASK_TYPES.INBOX]: false }))
  }
}

export const patchTask = (taskId, body, inboxType) => async (dispatch) => {
  try {
    await fetchingAPI(
      `${apiService.workflow}/tasks/${taskId}`,
      'PATCH',
      dispatch,
      JSON.stringify(body)
    )

    toast.success('Task updated')

    await dispatch(fetchTasks(inboxType))
  } catch (err) {
    toast.error('Failed to update task')
  }
}

export const fetchTaskComments = (taskId) => async (dispatch) => {
  const notes = await fetchingAPI(`${apiService.workflow}/tasks/${taskId}/note`, 'GET', dispatch)

  return notes
}

export const postTaskComment = (commentText, taskId) => async (dispatch, getState) => {
  const { user_id } = getState().currentUser
  const body = { note_text: commentText.trim(), user_id }

  return fetchingAPI(
    `${apiService.workflow}/tasks/${taskId}/note`,
    'POST',
    dispatch,
    JSON.stringify(body)
  )
}

export const addTaskComment = (commentText, taskId) => async (dispatch) => {
  try {
    await dispatch(postTaskComment(commentText, taskId))
    toast.success('Note added to task')
  } catch (err) {
    toast.error('Failed to add note to task')
  }
}

export const patchTaskComment =
  (updatedProperty, commentId, taskId) => async (dispatch, getState) => {
    const { user_id } = getState().currentUser

    return fetchingAPI(
      `${apiService.workflow}/tasks/${taskId}/note/${commentId}`,
      'PATCH',
      dispatch,
      JSON.stringify({ ...updatedProperty, user_id })
    )
  }

export const updateTaskComment =
  (updatedProperty, commentId, taskId, isDelete) => async (dispatch) => {
    try {
      await dispatch(patchTaskComment(updatedProperty, commentId, taskId))

      toast.success(`Note ${isDelete ? 'deleted' : 'updated'}`)
    } catch (err) {
      toast.error(`Failed to ${isDelete ? 'delete' : 'update'} note`)
    }
  }

export const createManualTask =
  (callId, userId, organizationId, name, reason, timestamp, type) => async (dispatch) => {
    try {
      const body = {
        name,
        call_id: callId,
        agent_id: userId,
        description: reason,
        type,
        status: 'ready_for_review',
        identifier_id: callId,
      }
      if (timestamp) {
        body.manual_timestamp = timestamp
      }
      await fetchingAPI(
        `${apiService.workflow}/tasks?requested_organization_id=${organizationId}`,
        'POST',
        dispatch,
        JSON.stringify(body)
      )
      const successMessage = type === 'compliance' ? 'Call escalated' : 'Coaching task created'
      toast.success(successMessage)
    } catch (err) {
      const failureMessage =
        type === 'compliance' ? 'Failed to escalate call' : 'Failed to create coaching task'
      toast.error(failureMessage)
    }
  }

const fetchMediaForEvidence = async (evidenceArray, dispatch) => {
  const updatedEvidenceArray = []

  for (const evidence of evidenceArray) {
    let updatedEvidence = { ...evidence }

    const callData = await dispatch(fetchCall(evidence.call_id))
    updatedEvidence = { ...updatedEvidence, call_data: callData }

    const audioResponse = await fetchAudioForCallId(evidence.call_id, dispatch)

    if (audioResponse) {
      const callAudio = {
        call_id: evidence.call_id,
        audio_url: audioResponse.audioUrl,
        audio_status: audioResponse.audioStatus,
        audio_url_expiration: audioResponse.audioUrlExpiration,
      }

      updatedEvidence = { ...updatedEvidence, call_audio: callAudio }
    } else {
      updatedEvidence = {
        ...updatedEvidence,
        call_audio: { call_id: evidence.call_id, audio_status: 'unavailable' },
      }
    }

    const videoResponse = await fetchVideoForCallId(evidence.call_id, dispatch)

    if (videoResponse) {
      const callVideo = {
        call_id: evidence.call_id,
        video_urls: videoResponse.videoUrls,
        video_status: videoResponse.videoStatus,
      }

      updatedEvidence = { ...updatedEvidence, call_video: callVideo }
    } else {
      updatedEvidence = {
        ...updatedEvidence,
        call_video: { call_id: evidence.call_id, video_status: 'unavailable' },
      }
    }

    updatedEvidenceArray.push(updatedEvidence)
  }

  return updatedEvidenceArray
}

export const fetchTaskByCriteriaScoreIds =
  (criteriaScoreIds = [], task = {}) =>
  async (dispatch) => {
    const params = queryString.stringify({ criteria_score_ids: criteriaScoreIds })

    dispatch(redux.setLoading({ task: true }))

    try {
      const evidenceArray = await fetchingAPI(
        `${apiService.scorecard}/scoring/scores/criteria_scores/by_ids?${params}`,
        'GET',
        dispatch
      )

      const updatedEvidenceArray = await fetchMediaForEvidence(evidenceArray, dispatch)
      const newTask = {
        ...task,
        score_evidence: task.score_evidence.map((evidence) => {
          const foundEvidence =
            updatedEvidenceArray.find(
              (updatedEvidence) =>
                updatedEvidence.criteria_score_id === evidence.scorecard_criteria_score_id
            ) || {}

          return { ...evidence, ...foundEvidence }
        }),
      }

      dispatch(redux.setTask(newTask))
    } catch (err) {
    } finally {
      dispatch(redux.setLoading({ task: false }))
    }
  }

export const fetchScorecardByIdentifierId = (originalTask) => async (dispatch) => {
  try {
    const [response] = await fetchingAPI(
      `${apiService.scorecard}/scoring/scores?scorecard_ids=${originalTask.identifier_id}&requested_organization_id=${originalTask.organization_id}`,
      'GET',
      dispatch
    )

    const task = cloneDeep(originalTask)
    task.score = response

    const manualCriteria = []

    task.score.section_scores.forEach((section) => {
      section.measure_scores.forEach((measure) => {
        measure.criteria_scores.forEach((criteria) => {
          if (criteria.criteria_type === 'manual') {
            manualCriteria.push(criteria)
          }
        })
      })
    })
    task.manualCriteria = manualCriteria
    dispatch(redux.setTask(task))
  } catch (err) {
    toast.error('Failed to fetch scorecard')
  }
}

export const resolveManualScore = (updatedCriteria, scorecardId) => async (dispatch) => {
  try {
    await fetchingAPI(
      `${apiService.scorecard}/scoring/scores/${scorecardId}/update_criteria`,
      'POST',
      dispatch,
      JSON.stringify(updatedCriteria)
    )
  } catch {
    toast.error('Failed to update scores')
  }
}
export const fetchTaskByCallEvidence =
  (task = {}) =>
  async (dispatch) => {
    dispatch(redux.setLoading({ task: true }))

    try {
      const updatedEvidenceArray = await fetchMediaForEvidence(task.call_evidence, dispatch)
      const newTask = {
        ...task,
        call_evidence: task.call_evidence.map((evidence) => {
          const foundEvidence =
            updatedEvidenceArray.find(
              (updatedEvidence) => updatedEvidence.call_id === evidence.call_id
            ) || {}

          return { ...evidence, ...foundEvidence }
        }),
      }

      dispatch(redux.setTask(newTask))
    } catch (err) {
    } finally {
      dispatch(redux.setLoading({ task: false }))
    }
  }
