// Action Types
export const SET_MASS_PLAYBOOK_UPDATE_MATCHES = 'playbooks/setMassPlaybookUpdateMatches'
export const SET_MASS_PLAYBOOK_UPDATE_CHECKLIST_SELECTION =
  'playbooks/setMassPlaybookUpdateChecklistSelection'
export const SET_PLAYBOOKS_LOADING = 'playbooks/setPlaybooksLoading'
export const SET_PLAYBOOKS = 'playbooks/setPlaybooks'

// Action Creators
export const setLoading = (payload) => ({ type: SET_PLAYBOOKS_LOADING, payload })
export const setMassPlaybookUpdateMatches = (payload) => ({
  type: SET_MASS_PLAYBOOK_UPDATE_MATCHES,
  payload,
})
export const setMassPlaybookUpdateChecklistSelection = (payload) => ({
  type: SET_MASS_PLAYBOOK_UPDATE_CHECKLIST_SELECTION,
  payload,
})
export const setPlaybooks = (payload) => ({ type: SET_PLAYBOOKS, payload })

// State
export const initialState = {
  loading: false,
  massPlaybookUpdateMatches: [],
  massPlaybookUpdateSelection: [],
  playbooks: [],
}

// Reducer
const playbooksReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLAYBOOKS_LOADING:
      return { ...state, loading: action.payload }
    case SET_MASS_PLAYBOOK_UPDATE_MATCHES:
      return { ...state, massPlaybookUpdateMatches: action.payload }
    case SET_MASS_PLAYBOOK_UPDATE_CHECKLIST_SELECTION:
      return { ...state, massPlaybookUpdateSelection: action.payload }
    case SET_PLAYBOOKS:
      return { ...state, playbooks: action.payload }
    default:
      return state
  }
}

export default playbooksReducer
