import { isEmpty } from 'lodash'

export const formatTaskStatus = (taskStatus) => {
  if (taskStatus === 'ready_for_review') {
    return 'Not Reviewed'
  }
  if (taskStatus === 'completed') {
    return 'Reviewed'
  }
  if (taskStatus === 'expired') {
    return 'Expired'
  }
  if (taskStatus === 'ignored') {
    return 'Ignored'
  }

  return 'Invalid Status'
}

export const formatTaskType = (taskType, scoreEvidence) => {
  if (taskType === 'coaching') {
    if (isEmpty(scoreEvidence)) {
      return 'Manual'
    }
    return 'Coaching'
  }
  if (taskType === 'compliance') {
    if (isEmpty(scoreEvidence)) {
      return 'Manual Escalation'
    }
    return 'Copilot Escalation'
  }
  if (taskType === 'qa_dispute') {
    return 'Dispute'
  }
  if (taskType === 'qa_manual_score') {
    return 'Pending Evaluation'
  }

  return 'Invalid Type'
}
