import React from 'react'
import { Button, Form } from 'semantic-ui-react'
import { Field, Formik } from 'formik'
import { IconFlag, IconFlag3 } from '@tabler/icons-react'
import { useDispatch } from 'react-redux'
import { startCase, isEmpty } from 'lodash'
import * as Yup from 'yup'
import classNames from 'classnames'

import { closeModal } from '@/reducers/ui/ui.redux'
import { createManualTask } from '@/reducers/tasks/tasks.actions'
import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'
import { NumberField, TextArea, TextField } from '@/components/forms/formik'

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  reason: Yup.string().required('Reason is required'),
  minutes: Yup.number().min(0, 'Enter a valid number'),
  seconds: Yup.number().min(0, 'Enter a valid number').max(59, 'Seconds cannot be greater than 59'),
})

export const ManualTaskModal = ({ callId, userId, organizationId, type }) => {
  const dispatch = useDispatch()
  // NOTE: If this modal needs to be expanded for a third manual type, PLEASE pass these variables in
  // as props instead of adding more conditionals here. Two is fine, three is not.
  const defaultName =
    type === 'compliance'
      ? `Manual Escalation for Call: ${callId}`
      : `Coaching Task for Call: ${callId}`
  const title = type === 'compliance' ? 'Submit for Compliance Review' : 'Submit Coaching Request'
  const description =
    type === 'compliance'
      ? ` Submit a call for compliance review when you identify something that may violate
                  company policies, industry regulations, or legal requirements. A compliance
                  manager will review the call and determine the next steps before any further
                  action is taken.`
      : `Use this option when a call can be used as a coaching opportunity for an agent. Coaching requests are intended to help agents improve their skills, correct behaviors, or learn best practices.`
  const placeholder =
    type === 'compliance'
      ? `Briefly explain what compliance issue was identified.`
      : `Briefly describe why this call is being submitted for coaching.`

  const saveEscalation = ({ name, reason, minutes, seconds }) => {
    let timestamp

    if (minutes || seconds) {
      timestamp = 0
    }
    if (minutes) {
      timestamp += minutes * 60
    }
    if (seconds) {
      timestamp += seconds
    }

    dispatch(createManualTask(callId, userId, organizationId, name, reason, timestamp, type))
    dispatch(closeModal())
  }

  return (
    <ButtonAndFormModal
      icon={
        <IconFlag
          className={classNames({
            'status-critical': type === 'compliance',
            'status-success': type === 'coaching',
          })}
        />
      }
      buttonProps={{ compact: true, secondary: true }}
      data-testid={`manual-task/${type}`}
      buttonLabel={startCase(type)}
      modalTitle={
        <div className="flex-align-center medium-gap">
          <IconFlag3
            className={classNames({
              'status-critical': type === 'compliance',
              'status-success': type === 'coaching',
            })}
          />
          <span>{title}</span>
        </div>
      }
      modalId={`manual-task/${type}`}
      form={
        <Formik
          validateOnMount
          initialValues={{
            reason: '',
            name: defaultName,
            minutes: 0,
            seconds: 0,
          }}
          validationSchema={validationSchema}
          onSubmit={saveEscalation}
        >
          {({ handleSubmit, errors }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <p>{description}</p>
                <Field name="name" component={TextField} placeholder="Name" label="Task Title" />
                <Field
                  name="reason"
                  component={TextArea}
                  placeholder={placeholder}
                  label="Reason"
                />

                <div className="flex gap mb">
                  <div className="label-style">Start at:</div>
                  <Field
                    className="flex-column"
                    style={{ width: '100px' }}
                    name="minutes"
                    component={NumberField}
                    label="Minutes"
                  />
                  <Field
                    className="flex-column"
                    style={{ width: '100px' }}
                    name="seconds"
                    component={NumberField}
                    label="Seconds"
                  />
                </div>
                <div className="modal-footer">
                  <Button
                    basic
                    data-testid="cancel-manual-task"
                    onClick={() => {
                      dispatch(closeModal())
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    data-testid="save-manual-task"
                    primary
                    type="submit"
                    onClick={handleSubmit}
                    disabled={!isEmpty(errors)}
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      }
    />
  )
}
