import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Segment } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'

import { updateMediaPlayer } from '@/reducers/media/media.redux'
import { MediaPlayer } from '@/components/media/MediaPlayer'
import { AudioPlayerProvider } from '@/components/media/AudioPlayerContext'
import { VideoPlayerProvider } from '@/components/media/VideoPlayerContext'
import { MediaPlayerPlaceholder } from '@/components/media/MediaPlayerPlaceholder'
import { CopyButton } from '@/components/buttons/CopyButton'
import { JumpToMomentPill } from '@/components/media/components/JumpToMomentPill'
import { formatTime } from '@/views/Calls/components/helpers'
import { TranscriptProvider } from '@/components/transcript/TranscriptContext'

export const CallEvidence = ({ evidence = [] }) => {
  const dispatch = useDispatch()
  const { mediaPlayer } = useSelector((state) => state.media)

  const handleSelectMediaPlayer = (audioData = {}, videoData = {}, progress = 0) => {
    dispatch(
      updateMediaPlayer({
        callId: audioData.call_id,
        audioUrl: audioData.audio_url,
        audioUrlExpiration: audioData.audio_expiration,
        audioStatus: audioData.audio_status,
        videoUrls: videoData.video_urls,
        videoStatus: videoData.video_status,
        isPlaying: true,
        audioError: false,
        videoError: false,
        progress,
      })
    )
  }

  const handleJumpToTimestamp = (data = {}, timestamp = 0) => {
    // If working with the active media player, send a hard selected event to update it
    if (data.call_id === mediaPlayer.callId) {
      dispatch(updateMediaPlayer({ hardSelectedEvent: { timestamp } }))
    } else {
      // Otherwise, start new media player at the specified timestamp
      handleSelectMediaPlayer(data.call_audio, data.call_video, timestamp)
    }
  }

  return evidence.map((data) => {
    return (
      <div className="call-evidence" key={data.call_id}>
        <h4 className="flex-align-center flex-space-between medium-gap">
          <div className="flex-align-center small-gap">
            <strong>Reference Call:</strong>
            <Link to={`/call-explorer/${data.call_id}`} target="_blank" className="text-button">
              {data.call_id}
            </Link>
            <CopyButton
              tooltipProps={{ position: 'top left' }}
              content={data.call_id}
              showLabel={false}
              showPopup
              iconOnly
            />
          </div>
        </h4>
        {data.call_audio?.audio_status === 'available' && (
          <div className={classNames({ mb: data.ai_explanation })}>
            {data.call_id === mediaPlayer.callId ? (
              <Segment className="not-padded" key={data.call_id}>
                <VideoPlayerProvider>
                  <AudioPlayerProvider>
                    <TranscriptProvider
                      transcript={data.call_data.transcript}
                      postcallTranscript={data.call_data.postcall_transcript}
                    >
                      <MediaPlayer
                        hideVolume
                        hidePlaybackRate
                        isTranscriptAvailable
                        transcriptConnected
                        transcriptMetadata={data.call_data.metadata}
                      />
                    </TranscriptProvider>
                  </AudioPlayerProvider>
                </VideoPlayerProvider>
              </Segment>
            ) : (
              <Segment className="not-padded" key={data.call_id}>
                <MediaPlayerPlaceholder
                  callAudio={data.call_audio}
                  handleSelectMediaPlayer={(progress = 0) => {
                    handleSelectMediaPlayer(data.call_audio, data.call_video, progress)
                  }}
                />
              </Segment>
            )}
            {data.manual_timestamp && (
              <JumpToMomentPill
                onClick={() => handleJumpToTimestamp(data, data.manual_timestamp)}
                formattedTime={formatTime(data.manual_timestamp)}
                dataTestId={data.call_id}
              />
            )}
          </div>
        )}

        {data.ai_explanation && <p>{data.ai_explanation}</p>}
      </div>
    )
  })
}
