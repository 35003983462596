import React, { useEffect, useRef } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Popup } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'

import { audioErrorSwitch } from '@/utils/helpers'
import { SKIP_MODIFIER, AUDIO_EXPIRATION_BUFFER } from '@/utils/constants'
import { setCallExplorerData } from '@/reducers/callSearch/callSearch.redux'
import { fetchAudioUrl, setHardSelectedEvent } from '@/reducers/callSearch/callSearch.actions'
import { useVideoPlayer } from '@/components/media/VideoPlayerContext'
import { useAudioPlayer } from '@/components/media/AudioPlayerContext'
import { SkipButton } from '@/components/media/components/SkipButton'
import { PlayPauseButton } from '@/components/media/components/PlayPauseButton'
import { PlaybackRateDropdown } from '@/components/media/components/PlaybackRateDropdown'
import { AudioTrackProgress } from '@/components/media/components/AudioTrackProgress'
import { AudioTrackTime } from '@/components/media/components/AudioTrackTime'
import { VolumeSlider } from '@/components/media/components/VolumeSlider'
import { VideoPlayerToggleButton } from '@/components/media/components/VideoPlayerToggleButton'

import { parseCallExplorerParams, updateCallExplorerParams } from './helpers'

import './AudioPlayer.scss'

export const AudioPlayer = ({
  transcriptScrollerRef = { current: null },
  isAudioAvailable,
  isVideoAvailable,
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()

  const trackProgressRef = useRef()
  const trackExpirationRef = useRef()

  const { callExplorer } = useSelector((state) => state.callSearch)
  const {
    audioUrlExpiration,
    audioDuration: duration,
    audioProgress: progress,
    audioPlaybackRate: playbackRate,
    audioPlaying: isPlaying,
    audioVolume: volume,
    audioError,
    audioUrl,
    callId,
    hardSelectedEvent,
  } = callExplorer
  const {
    videoRef,
    changeVideoPlaybackRate,
    jumpVideoTo,
    playVideo,
    pauseVideo,
    toggleVideoVisibility,
    isVideoVisible,
    addVideoEvent,
    removeVideoEvent,
  } = useVideoPlayer()
  const {
    audioRef,
    changeAudioPlaybackRate,
    jumpAudioTo,
    playAudio,
    pauseAudio,
    changeAudioVolume,
    addAudioEvent,
    removeAudioEvent,
  } = useAudioPlayer()

  const isDisabled = audioError && !isVideoAvailable
  const isPlayerVisible = isAudioAvailable || isVideoAvailable
  const isPopupDisabled = !audioError || isVideoAvailable

  const setIsPlaying = (value) => {
    if (isPlaying !== value) {
      dispatch(setCallExplorerData({ audioPlaying: value }))
    }
  }

  const setProgress = (value) => {
    dispatch(setCallExplorerData({ audioProgress: value }))

    if (isAudioAvailable) jumpAudioTo(value)
    if (isVideoAvailable) jumpVideoTo(value)
  }

  const setDuration = (value) => {
    dispatch(setCallExplorerData({ audioDuration: value }))
  }

  const setVolume = (value) => {
    dispatch(setCallExplorerData({ audioVolume: value }))
    changeAudioVolume(value)
  }

  const setPlaybackRateChange = (value) => {
    dispatch(setCallExplorerData({ audioPlaybackRate: value }))

    if (isAudioAvailable) changeAudioPlaybackRate(value)
    if (isVideoAvailable) changeVideoPlaybackRate(value)
  }

  const setErrorState = (value) => {
    audioErrorSwitch(audioRef.current.error.code, audioRef.current.error.message)
    dispatch(setCallExplorerData({ audioError: value }))
  }

  const togglePlayState = () => {
    setIsPlaying(!isPlaying)
  }

  const stopExpirationTracking = () => {
    clearInterval(trackExpirationRef.current)
    trackExpirationRef.current = null
  }

  const startExpirationTracking = () => {
    if (audioUrlExpiration > 0) {
      const intervalValue = audioUrlExpiration * 1000 - AUDIO_EXPIRATION_BUFFER

      trackExpirationRef.current = setInterval(() => {
        dispatch(fetchAudioUrl(callId))
      }, intervalValue)
    }
  }

  const stopProgressTracking = () => {
    clearInterval(trackProgressRef.current)
    trackProgressRef.current = null
  }

  const startProgressTracking = () => {
    // Clear any timers already running
    stopProgressTracking()

    trackProgressRef.current = setInterval(() => {
      // Use video refs if only video exists and no audio
      const currentTime = isAudioAvailable
        ? audioRef.current.currentTime
        : videoRef.current.currentTime
      const isEnded = isAudioAvailable ? audioRef.current.ended : videoRef.current.ended

      if (isEnded) {
        setProgress(duration)
        setIsPlaying(false)
      } else {
        dispatch(setCallExplorerData({ audioProgress: currentTime }))
      }
    }, [50])
  }

  const play = () => {
    if (isAudioAvailable) playAudio()
    if (isVideoAvailable) playVideo()

    startProgressTracking()
  }

  const pause = () => {
    if (isAudioAvailable) pauseAudio()
    if (isVideoAvailable) pauseVideo()

    stopProgressTracking()
  }

  const handleScrub = (event) => {
    const newTime = event.target.valueAsNumber

    setProgress(newTime)
    updateCallExplorerParams(location, history, { timestamp: newTime })
  }

  const handleForwardSkip = () => {
    const newTime = progress + SKIP_MODIFIER
    const canScrubForward = newTime < duration

    if (canScrubForward) {
      setProgress(newTime)
    } else {
      setProgress(duration)
    }
  }

  const handleBackSkip = () => {
    const newTime = progress - SKIP_MODIFIER
    const canScrubBack = newTime > 0

    if (canScrubBack) {
      setProgress(newTime)
    } else {
      setProgress(0)
    }
  }

  const handlePlaybackRateChange = (event, option) => {
    const updatedPlaybackRate = parseFloat(option.value)

    localStorage.setItem('audioPlaybackRate', updatedPlaybackRate)
    setPlaybackRateChange(updatedPlaybackRate)
  }

  const handleVolumeChange = (value) => {
    setVolume(value)
  }

  // doing this because we need access to the audio player ref in the transcriptViewer component
  // if I use just one ref (in transcriptviewer and pass to here) and don't create audioRef here,
  // it breaks all the tests because I can't pass a real ref to this component in tests
  useEffect(() => {
    transcriptScrollerRef.current = audioRef.current
  }, [audioRef.current])

  useEffect(() => {
    if (isPlaying) {
      if (progress === duration) {
        // Set the progress to the beginning if you try to play after the audio has ended.
        // This ensures it doesn't just start for a few milliseconds, go to the beginning, then pause.
        setProgress(0)
      }
      play()
    } else {
      pause()
      if (progress > 0 && progress < duration) {
        // Set timestamp where they paused
        updateCallExplorerParams(location, history, { timestamp: progress })
      }
    }
  }, [isPlaying])

  useEffect(() => {
    const newTime = hardSelectedEvent?.timestamp

    // There is no timestamp on render therefore requiring a value check here
    if (newTime !== undefined && !audioError) {
      dispatch(setCallExplorerData({ audioProgress: newTime }))
      jumpAudioTo(newTime)

      // This was causing an infinite loop in TranscriptViewer -> onSeeked, because it would jumpTo the video,
      // which would seek, which would hit this event, and repeat. If we ignore this jumpTo, it will just update
      // the audio progress and let the full screen seek handle itself
      if (isVideoAvailable && !hardSelectedEvent.ignoreVideo) {
        jumpVideoTo(newTime)
      }
    }
  }, [hardSelectedEvent])

  useEffect(() => {
    const durationListener = (event) => {
      setDuration(event.target.duration)
    }
    const errorListener = () => {
      setErrorState(true)
    }

    addAudioEvent('loadedmetadata', durationListener)
    addAudioEvent('error', errorListener)

    if (!isAudioAvailable && isVideoAvailable) {
      addVideoEvent('loadedmetadata', durationListener)
    }

    startExpirationTracking()

    const storedPlaybackRate = localStorage.getItem('audioPlaybackRate')
    if (storedPlaybackRate) {
      setPlaybackRateChange(parseFloat(storedPlaybackRate))
    }

    const { timestamp: strTimestamp, eventId, autoplay } = parseCallExplorerParams(location)
    const timestamp = Number(strTimestamp)

    if (timestamp) {
      setProgress(timestamp)

      // we set this in EventViewer if there's a selected event
      if (!eventId) {
        dispatch(setHardSelectedEvent({ timestamp }))
      }

      if (autoplay) {
        setIsPlaying(true)
      }
    }

    return () => {
      removeAudioEvent('loadedmetadata', durationListener)
      removeAudioEvent('error', errorListener)

      if (!isAudioAvailable && isVideoAvailable) {
        removeVideoEvent('loadedmetadata', durationListener)
      }

      stopProgressTracking()
      stopExpirationTracking()
    }
  }, [])

  return (
    <>
      <audio src={audioUrl} ref={audioRef} />
      <div className="call-explorer-audio-player">
        {isPlayerVisible && (
          <Popup
            inverted
            disabled={isPopupDisabled}
            content={
              <span data-testid="error-message">Audio file is unavailable at this time</span>
            }
            trigger={
              <div className="audio-controls-container" data-testid="audio-controls">
                <PlayPauseButton
                  isPlaying={isPlaying}
                  togglePlayState={togglePlayState}
                  isDisabled={isDisabled}
                  autoFocus
                />
                <div className="flex-align-center small-gap">
                  <SkipButton
                    modifier={SKIP_MODIFIER}
                    handleSkip={handleBackSkip}
                    isDisabled={isDisabled}
                  />
                  <SkipButton
                    modifier={SKIP_MODIFIER}
                    handleSkip={handleForwardSkip}
                    isForwardSkip
                    isDisabled={isDisabled}
                  />
                </div>
                <VolumeSlider
                  volume={volume}
                  handleVolumeChange={handleVolumeChange}
                  isDisabled={audioError}
                />
              </div>
            }
          />
        )}
        <div className="additional-audio-options">
          {isVideoAvailable && (
            <VideoPlayerToggleButton
              isVideoVisible={isVideoVisible}
              toggleVideoVisibility={toggleVideoVisibility}
            />
          )}
          {isPlayerVisible && (
            <PlaybackRateDropdown
              isDisabled={isDisabled}
              handlePlaybackRateChange={handlePlaybackRateChange}
              playbackRate={playbackRate}
            />
          )}
        </div>
      </div>
      {isPlayerVisible && (
        <div data-testid="audio-progress-track">
          <AudioTrackTime duration={duration} progress={progress} />
          <AudioTrackProgress
            isDisabled={isDisabled}
            duration={duration}
            progress={progress}
            handleScrub={handleScrub}
          />
        </div>
      )}
    </>
  )
}
