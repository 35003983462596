import React, { useEffect, useState } from 'react'
import {
  Accordion,
  AccordionContent,
  AccordionTitle,
  Button,
  Checkbox,
  Icon,
  Input,
} from 'semantic-ui-react'
import { Pill } from '@/components/pills/Pill'
import { isEmpty, isNil } from 'lodash'
import { reorder } from '@/utils/helpers'
import { IconSparkles } from '@tabler/icons-react'

import { Drag, DragAndDrop, Drop } from '../../dragDrop'

import './EditColumnsForm.scss'
// extra columns are the inner columns
export const EditColumnsForm = ({ handleSubmit, allColumns, extraColumns, loading, onClose }) => {
  const [tempColumns, setTempColumns] = useState(allColumns)
  const [innerColumnsGroupedByParent, setInnerColumnsGroupedByParent] = useState([])
  const [toggleAll, setToggleAll] = useState(false)
  const [search, setSearch] = useState('')
  const [activeIndexes, setActiveIndexes] = useState([])
  const [outerColumnsSearchable, setOuterColumnsSearchable] = useState(tempColumns)
  const [innerColumnsSearchable, setInnerColumnsSearchable] = useState([])

  useEffect(() => {
    setTempColumns(
      allColumns.map((column) => ({
        ...column,
        is_hidden: isNil(column.is_hidden) ? false : column.is_hidden,
        accessor: isNil(column.accessor) ? column.label.replaceAll(' ', '') : column.accessor,
      }))
    )
    setOuterColumnsSearchable(tempColumns)
    const groupedColumns = extraColumns?.reduce((acc, column) => {
      const parentAccessor = column.parent
      if (!acc[parentAccessor]) {
        acc[parentAccessor] = []
      }
      column.is_hidden = !isNil(column.is_hidden) ? column.is_hidden : false
      acc[parentAccessor].push(column)
      return acc
    }, {})
    setInnerColumnsGroupedByParent(groupedColumns)
    setInnerColumnsSearchable(groupedColumns)
    const parentAccessors = allColumns
      .filter((column) => column.isParent)
      .map((column) => {
        return column.accessor
      })
    setActiveIndexes(parentAccessors)
  }, [])

  const handleReorder = (result) => {
    const { source, destination } = result
    if (!destination) return

    const reorderedItems = reorder(tempColumns, source.index, destination.index)
    setTempColumns(reorderedItems)
    setOuterColumnsSearchable(reorderedItems)
  }
  const handleReorderInside = (result) => {
    const { source, destination } = result
    if (!destination) return

    const sourceGroup = source.droppableId
    const destinationGroup = destination.droppableId

    if (sourceGroup !== destinationGroup) return

    const items = innerColumnsGroupedByParent[sourceGroup]

    const reorderedItems = reorder(items, source.index, destination.index)

    setInnerColumnsGroupedByParent({
      ...innerColumnsGroupedByParent,
      [sourceGroup]: reorderedItems,
    })
    setInnerColumnsSearchable({
      ...innerColumnsGroupedByParent,
      [sourceGroup]: reorderedItems,
    })
  }

  const handleUpdateColumnVisibility = (accessor, isHidden) => {
    setTempColumns((prevColumns) => {
      const updatedColumns = prevColumns.map((column) =>
        column.accessor === accessor ? { ...column, is_hidden: !isHidden } : column
      )
      setOuterColumnsSearchable(updatedColumns)
      return updatedColumns
    })
  }
  const handleToggleInnerVisibility = (accessor, parent, isHidden) => {
    setInnerColumnsGroupedByParent((prevInnerColumnsGroupedByParent) => {
      const parentCols = prevInnerColumnsGroupedByParent[parent].map((column) =>
        column.accessor === accessor ? { ...column, is_hidden: !isHidden } : column
      )
      setInnerColumnsSearchable((prevSearchable) => ({
        ...prevSearchable,
        [parent]: parentCols,
      }))
      return { ...prevInnerColumnsGroupedByParent, [parent]: parentCols }
    })
  }

  const handleToggleAll = () => {
    const changedTemp = tempColumns.map((column) => {
      return { ...column, is_hidden: toggleAll }
    })
    setTempColumns(changedTemp)
    setOuterColumnsSearchable(changedTemp)
    const changedInner = extraColumns?.reduce((acc, column) => {
      const parentAccessor = column.parent
      if (!acc[parentAccessor]) {
        acc[parentAccessor] = []
      }
      column.is_hidden = toggleAll
      acc[parentAccessor].push(column)
      return acc
    }, {})
    setInnerColumnsGroupedByParent(changedInner)
    setInnerColumnsSearchable(changedInner)
    setToggleAll(!toggleAll)
  }

  // Right now default is all is visible, might change later
  const handleResetToDefaults = () => {
    const changedTemp = tempColumns.map((column) => {
      return { ...column, is_hidden: false }
    })
    setTempColumns(changedTemp)
    setOuterColumnsSearchable(changedTemp)
    const changedInner = extraColumns?.reduce((acc, column) => {
      const parentAccessor = column.parent
      if (!acc[parentAccessor]) {
        acc[parentAccessor] = []
      }
      column.is_hidden = false
      acc[parentAccessor].push(column)
      return acc
    }, {})
    setInnerColumnsGroupedByParent(changedInner)
    setInnerColumnsSearchable(changedInner)
    setToggleAll(true)
  }

  const handleOnSearch = (newSearch) => {
    setSearch(newSearch)
    const searchLowerCase = newSearch.toLowerCase()
    const result = {}
    const parentColumnsWithChildren = []
    // eslint-disable-next-line guard-for-in
    for (const key in innerColumnsGroupedByParent) {
      result[key] = innerColumnsGroupedByParent[key].filter((item) =>
        item.label.toLowerCase().includes(searchLowerCase)
      )
      if (result[key].length > 0) parentColumnsWithChildren.push(key)
    }
    setInnerColumnsSearchable(result)

    setOuterColumnsSearchable(
      tempColumns.filter((column) => {
        if (parentColumnsWithChildren.length === 0) {
          return column.label.toLowerCase().startsWith(searchLowerCase)
        }
        return (
          column.label.toLowerCase().includes(searchLowerCase) ||
          (column.isParent && parentColumnsWithChildren.includes(column.accessor))
        )
      })
    )
  }
  const handleClickAccordion = (accessor) => {
    if (activeIndexes.includes(accessor)) {
      setActiveIndexes(activeIndexes.filter((item) => item !== accessor))
    } else {
      setActiveIndexes([...activeIndexes, accessor])
    }
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()

        handleSubmit([...tempColumns, ...[].concat(...Object.values(innerColumnsGroupedByParent))])

        onClose()
      }}
    >
      <div className="edit-columns-form">
        <div className="search-and-buttons-row">
          <Input
            data-testid="edit-columns-form-search"
            type="text"
            id="edit-columns-form-search"
            placeholder="Start typing..."
            onChange={(val) => {
              handleOnSearch(val.target.value)
            }}
            value={search}
          />

          <div className="flex small-gap">
            <Button
              onClick={handleToggleAll}
              data-testid="edit-columns-form-toggle-all"
              secondary
              type="button"
              loading={loading}
              disabled={loading}
            >
              Toggle All
            </Button>
            <Button
              onClick={handleResetToDefaults}
              data-testid="edit-columns-form-reset-defaults"
              secondary
              type="button"
              loading={loading}
              disabled={loading}
            >
              Reset Defaults
            </Button>
          </div>
        </div>

        <div className="edit-columns-row edit-columns-form-column-header">
          <div className="label">Column Name</div>
          <div className="label">Visibility</div>
        </div>
        <div className="edit-columns-form-table">
          <DragAndDrop onDragEnd={handleReorder}>
            <Drop droppableId="droppable">
              {outerColumnsSearchable
                .filter((column) => {
                  return !(column.isParent && isEmpty(innerColumnsGroupedByParent[column.accessor]))
                })
                .map((column, index) => {
                  return (
                    <Drag
                      key={column.accessor}
                      draggableId={column.accessor}
                      index={index}
                      alwaysShowIcon
                      showDragStyle
                      isModal
                      dataTestId={`${column.accessor}`}
                      outer={column.isParent}
                      nestedEntriesCount={
                        column.isParent ? innerColumnsGroupedByParent[column.accessor]?.length : []
                      }
                    >
                      <div
                        data-testid={`edit-columns-row-column-${column.accessor}`}
                        className={`edit-columns-row${column.isParent ? '-outer' : ''}`}
                      >
                        {!column.isParent && (
                          <>
                            <div className="title">
                              <strong>{column.label}</strong>
                            </div>

                            <Checkbox
                              data-testid={`edit-columns-row-checkbox-${column.accessor}`}
                              toggle
                              checked={!column.is_hidden}
                              onChange={() => {
                                handleUpdateColumnVisibility(column.accessor, column.is_hidden)
                              }}
                            />
                          </>
                        )}

                        {column.isParent && (
                          <>
                            <Accordion>
                              <AccordionTitle
                                active={activeIndexes.includes(column.accessor)}
                                onClick={() => handleClickAccordion(column.accessor)}
                              >
                                <Icon
                                  name="dropdown"
                                  className="accordion-icon"
                                  style={{ margin: '0' }}
                                />
                                <div>
                                  <strong>{column.label}</strong>
                                </div>
                                {column.pillLabel && (
                                  <Pill
                                    className="edit-columns-pill"
                                    small
                                    magical
                                    icon={<IconSparkles />}
                                  >
                                    {column.pillLabel}
                                  </Pill>
                                )}
                              </AccordionTitle>
                              <AccordionContent active={activeIndexes.includes(column.accessor)}>
                                <DragAndDrop onDragEnd={handleReorderInside}>
                                  <Drop
                                    droppableId={`${column.accessor}`}
                                    type="droppable-entry"
                                    className="inner-item"
                                  >
                                    {innerColumnsSearchable[column.accessor]?.map(
                                      (innerColumn, innerIndex) => {
                                        return (
                                          <Drag
                                            key={innerColumn.accessor}
                                            draggableId={innerColumn.accessor}
                                            index={innerIndex}
                                            dataTestId={`${innerColumn.accessor}`}
                                            alwaysShowIcon
                                            showDragStyle
                                            isModal
                                          >
                                            <div
                                              data-testid={`edit-columns-row-column-${innerColumn.accessor}`}
                                              className="edit-columns-row"
                                            >
                                              <div>
                                                <strong>{innerColumn.label}</strong>
                                              </div>

                                              <Checkbox
                                                toggle
                                                data-testid={`edit-columns-row-checkbox-${innerColumn.accessor}`}
                                                checked={!innerColumn.is_hidden}
                                                onChange={() => {
                                                  handleToggleInnerVisibility(
                                                    innerColumn.accessor,
                                                    innerColumn.parent,
                                                    innerColumn.is_hidden
                                                  )
                                                }}
                                              />
                                            </div>
                                          </Drag>
                                        )
                                      }
                                    )}
                                  </Drop>
                                </DragAndDrop>
                              </AccordionContent>
                            </Accordion>
                          </>
                        )}
                      </div>
                    </Drag>
                  )
                })}
            </Drop>
          </DragAndDrop>
        </div>
      </div>
      <div className="modal-footer">
        <Button secondary data-testid="abstract-form-close" type="button" onClick={onClose}>
          Discard
        </Button>
        <Button
          data-testid="edit-columns-form-submit"
          primary
          type="submit"
          loading={loading}
          disabled={loading}
        >
          Save
        </Button>
      </div>
    </form>
  )
}
