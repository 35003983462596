import React from 'react'
import { NavLink } from 'react-router-dom'
import { IconChevronRight } from '@tabler/icons-react'
import classNames from 'classnames'

import { getTestId } from './Sidebar.helpers'
import { iconMap } from './Sidebar.constants'

export const SidebarSection = ({
  section,
  title,
  links = [],
  noCollapse,
  collapsedSections = [],
  handleToggleCollapseSection,
  currentLinkRef,
  currentPath,
}) => {
  const sectionIsCollapsed = collapsedSections.includes(section)
  const noCollapseLink = links[0]

  return (
    <section className="balto-sidebar__section" data-testid={`sidebar-section-${section}`}>
      {!noCollapse ? (
        <button
          data-testid={`sidebar-section-${section}-button`}
          type="button"
          className="balto-sidebar__section-header"
          onClick={() => handleToggleCollapseSection(section)}
        >
          <div className="flex-align-center medium-gap">
            {iconMap[section]}
            <div className="balto-sidebar__section-header-name">{title}</div>
          </div>
          <div className="ui button basic icon compact svg-button balto-sidebar__collapse-button">
            <IconChevronRight
              className={classNames('icon-accordion', {
                'icon-accordion--expanded': !sectionIsCollapsed,
              })}
            />
          </div>
        </button>
      ) : (
        <NavLink
          data-testid={`sidebar-section-${section}-link`}
          className="balto-sidebar__section-header-link"
          to={noCollapseLink.path}
          exact={noCollapseLink.exact}
          ref={noCollapseLink.path === currentPath ? currentLinkRef : null}
        >
          <div className="flex-align-center medium-gap">
            {iconMap[section]}
            <div className="balto-sidebar__section-header-name">{title}</div>
          </div>
        </NavLink>
      )}

      {!sectionIsCollapsed && !noCollapse && (
        <nav className="balto-sidebar__section-nav">
          {links.map((link) => {
            return (
              <NavLink
                data-testid={getTestId(link.path)}
                key={link.path}
                to={link.path}
                exact={link.exact}
                className={classNames({ 'nested-link': link.nested })}
                ref={link.path === currentPath ? currentLinkRef : null}
              >
                {!link.nested && <div className="active-indicator" />}
                <div className="balto-sidebar__section-nav-link"> {link.label}</div>
              </NavLink>
            )
          })}
        </nav>
      )}
    </section>
  )
}
