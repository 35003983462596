import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { isEmpty, sortBy } from 'lodash'

import { Breadcrumbs } from '@/components/forms/Breadcrumbs/Breadcrumbs'
import { ProgressBar } from '@/components/navigation/ProgressBar'
import { MarkAsReviewed } from '@/views/Playlists/components/MarkAsReviewed'
import { AddToPlaylist } from '@/views/Playlists/components/AddToPlaylist'
import { ScoreForScorecard } from '@/views/Scorecards/ScoreForScorecard'
import { ManualTaskModal } from '@/views/Calls/components/ManualTaskModal'
import { CopyButton } from '@/components/buttons/CopyButton'

import { DownloadDropdown } from './DownloadDropdown'
import { parseCallExplorerParams } from './helpers'

export const CallExplorerHeader = ({ callId, playlist, metadata }) => {
  const location = useLocation()
  const { callExplorer } = useSelector((state) => state.callSearch)
  const { qa_copilot_enabled: copilotEnabled } = useSelector((state) => state.currentOrganization)
  const { organizationid } = useSelector((state) => state.currentUser)
  const isAdmin = organizationid === 1
  const { associatedCallIds, audioStatus, screenCaptureStatus, audioError, screenCaptureError } =
    callExplorer

  const isAudioAvailable = audioStatus === 'available'
  const isVideoAvailable = screenCaptureStatus === 'available'

  const { multi_call_scoring_enabled: multiCallScoringEnabled } = useSelector(
    (state) => state.currentOrganization
  )
  const sortedassociatedCallIds = sortBy(associatedCallIds)
  const indexOfViewedCall = sortedassociatedCallIds.indexOf(parseInt(callId, 10))
  const callExplorerLinks = sortedassociatedCallIds.map((id, index) => ({
    slug: `/call-explorer/${id}`,
    label: `Call ${index + 1} of ${sortedassociatedCallIds.length}`,
  }))
  const shouldShowMultiCallScoring = multiCallScoringEnabled && !isEmpty(associatedCallIds)

  const { playlistUuid } = parseCallExplorerParams(location)

  let breadcrumbs = [
    {
      label: 'Calls',
      link: '/call-explorer',
    },
  ]
  let playlistCall

  if (playlistUuid && !isEmpty(playlist)) {
    if (playlist.type === 'coaching') {
      breadcrumbs = [
        {
          label: 'Coaching',
          link: '/coaching',
          dataTestId: 'breadcrumb-coaching',
        },
      ]
    } else {
      // eslint-disable-next-line eqeqeq
      playlistCall = playlist.playlist_calls.find((call) => call.call_id == callId)
      breadcrumbs = [
        {
          label: 'Playlists',
          link: '/playlists',
          dataTestId: 'breadcrumb-playlists',
        },
        {
          label: playlist.name,
          link: `/playlists/${playlistUuid}?organization_id=${metadata.organization_id}`,
          dataTestId: 'breadcrumb-playlist',
        },
      ]
    }
  }
  const copiableCallId = (
    <div className="flex-align-center small-gap">
      {callId}
      <CopyButton
        tooltipProps={{ position: 'bottom left' }}
        content={callId}
        showLabel={false}
        showPopup
        iconOnly
      />
    </div>
  )

  const currentLink = playlistCall?.alias ? `${playlistCall.alias} (${callId})` : copiableCallId

  return (
    <header className="page-header call-explorer-page-header" data-testid="call-explorer-page">
      <div className="flex-align-center medium-gap">
        <Breadcrumbs backLinks={breadcrumbs} currentLink={currentLink} />
        {shouldShowMultiCallScoring && (
          <ProgressBar
            currentPage={indexOfViewedCall + 1}
            totalPages={associatedCallIds.length}
            links={callExplorerLinks}
          />
        )}
      </div>
      <div className="flex-align-center medium-gap">
        <MarkAsReviewed compact />
        {isAdmin && (
          <ManualTaskModal
            callId={callId}
            userId={callExplorer.metadata.agent_id}
            organizationId={callExplorer.metadata.organization_id}
            type="compliance"
          />
        )}
        {isAdmin && (
          <ManualTaskModal
            callId={callId}
            userId={callExplorer.metadata.agent_id}
            organizationId={callExplorer.metadata.organization_id}
            type="coaching"
          />
        )}
        {metadata.organization_id && (
          <AddToPlaylist
            compact
            callIds={[callId]}
            organizationId={metadata.organization_id}
            showCoachingPlaylist={false}
          />
        )}
        {metadata.organization_id && copilotEnabled && (
          <ScoreForScorecard
            callId={callId}
            buttonProps={{
              content: 'Score Call',
              className: 'secondary compact',
            }}
            organizationId={metadata.organization_id}
          />
        )}
        <DownloadDropdown
          audioError={audioError}
          isAudioAvailable={isAudioAvailable}
          isVideoAvailable={isVideoAvailable}
          screenCaptureError={screenCaptureError}
        />
      </div>
    </header>
  )
}
