import React, { useState } from 'react'

import Select from '../../forms/Select'
import { FilterColumnButton } from '../FilterColumnButton'
import { FilterPill } from '../FilterPill'
import { FilterTypePopup } from './FilterTypePopup'
import { getDynamicOptions } from '../filter.helpers'

export const FilterTypeSelect = ({
  autoFocus = true,
  isClearable = true,
  schema,
  rows,
  value,
  loading,
  updateSearch,
  columnAccessor,
  showAllOnEmpty = false,
  popupProps = {},
}) => {
  const [open, setOpen] = useState(false)

  const options = schema.options || getDynamicOptions(rows, schema)
  const isSelected = options.some((option) => option.value === value)
  const displayLabel = options.find(
    (option) => option?.value.toString() === value?.toString()
  )?.label

  return (
    <FilterTypePopup
      open={open}
      setOpen={setOpen}
      position={columnAccessor ? 'top left' : 'bottom left'}
      popupProps={popupProps}
      trigger={
        columnAccessor ? (
          <FilterColumnButton
            type="select"
            columnAccessor={columnAccessor}
            displayLabel={displayLabel || schema.label}
            value={value}
            hasValue={isSelected}
          />
        ) : (
          <FilterPill
            showAllOnEmpty={showAllOnEmpty}
            clickable
            isClearable={isClearable && isSelected}
            handleRemoveFilter={(event) => {
              event.stopPropagation()

              updateSearch(schema.accessor, '')
            }}
            loading={loading}
            label={schema.label}
            value={displayLabel}
          />
        )
      }
    >
      <Select
        isClearable={isClearable}
        autoFocus={autoFocus}
        menuIsOpen
        label={schema.label}
        options={options}
        loading={loading}
        value={value}
        onChange={(option, action) => {
          if (action.action === 'clear') {
            updateSearch(schema.accessor, '')
          } else {
            updateSearch(schema.accessor, option.value)
          }
          setOpen(false)
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            setOpen(false)
          }
        }}
      />
    </FilterTypePopup>
  )
}
