import React from 'react'
import classNames from 'classnames'
import { Button, Popup } from 'semantic-ui-react'
import moment from 'moment'
import { startCase } from 'lodash'

import { Pill } from '@/components/pills/Pill'
import { Drawer } from '@/components/drawer/Drawer'
import { CopyButton } from '@/components/buttons/CopyButton'

import './TaskCard.scss'

export const TaskCard = ({
  taskType,
  title,
  description,
  status,
  date,
  className,
  handleSecondaryAction,
  secondaryActionButtonText = 'Ignore',
  primaryActionButtonText = 'Review',
  children,
  location = {},
  ...props
}) => {
  const createdAt = moment.utc(props.created_at).local()
  const dataTestId = `task-card-${props.id}`
  const taskUrl = `${window.location.origin}${location.pathname}${location.search}`

  const getTaskTitle = (taskType) => {
    if (taskType.startsWith('qa')) {
      return 'QA Task'
    }
    return `${startCase(taskType)} Task`
  }

  return (
    <div className={classNames('task-card', className)}>
      <header>
        <h2 data-testid={`${dataTestId}-title`}>{title}</h2>
      </header>
      {description && (
        <section>
          <p data-testid={`${dataTestId}-description`}>{description}</p>
        </section>
      )}
      <footer>
        <div className="task-card__metadata">
          <div>
            <Popup
              position="top left"
              inverted
              content={createdAt.format('MMMM Do YYYY, h:mm:ss a')}
              trigger={<small>Generated {createdAt.fromNow()}</small>}
            />
          </div>
          <div className="task-card__metadata--divider" />
          <div>
            <Pill
              xs
              success={status === 'completed'}
              emphasized={status === 'ready_for_review'}
              magical={status === 'collecting_evidence'}
              className="uppercase"
            >
              {startCase(status)}
            </Pill>
          </div>
        </div>
        <div className="flex-align-center small-gap">
          {handleSecondaryAction && (
            <Button type="button" basic onClick={handleSecondaryAction}>
              {secondaryActionButtonText}
            </Button>
          )}
          <Drawer
            id={props.id}
            wide
            title={
              <div
                className="flex-align-center medium-gap"
                data-testid={`${dataTestId}-drawer-title`}
              >
                {getTaskTitle(taskType)}
                <CopyButton content={taskUrl} name="Link" data-testid={`${dataTestId}-copy-link`} />
              </div>
            }
            trigger={
              <Button type="button" secondary>
                {primaryActionButtonText}
              </Button>
            }
          >
            {children}
          </Drawer>
        </div>
      </footer>
    </div>
  )
}
