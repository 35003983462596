import * as SIDEBAR from './Sidebar.constants'

export const getTestId = (path) => {
  const removeSlash = path.split('/')
  removeSlash.shift()
  const testIdPath = removeSlash.join('-')

  return `${testIdPath}-link`
}

export const getAccess = (currentUser, currentOrganization, flags) => {
  const isBaltoAdmin = currentUser.organizationid === 1
  const isHierarchyManager = currentUser.hierarchy_manager
  const hasChildOrgSelected =
    isHierarchyManager && currentUser.organizationid !== currentUser.own_organization_id
  const hasGamificationAccess =
    currentUser.game_admin || currentUser.game_operator || currentUser.game_monitor
  const hasRTCAccess =
    (currentUser.realtime_coaching_access || currentUser.real_time_management_access) &&
    currentUser.org_realtime_coaching_access &&
    !hasChildOrgSelected
  const hasCopilotAccess = currentOrganization.qa_copilot_enabled && currentUser.edit_qa_copilot
  const hasRTQAccess = currentOrganization.qa_enabled && currentUser.edit_qa
  const hasDisputesAccess = currentOrganization.qa_disputes_enabled
  const hasPlaybookEditAccess = currentUser.edit_config
  const hasNotetakerAccess = hasPlaybookEditAccess && currentOrganization.call_summarization_enabled
  const hasLiveListenAccess = !!flags.showCommandCenter

  return {
    isBaltoAdmin,
    isHierarchyManager,
    hasChildOrgSelected,
    hasGamificationAccess,
    hasRTCAccess,
    hasCopilotAccess,
    hasRTQAccess,
    hasDisputesAccess,
    hasNotetakerAccess,
    hasLiveListenAccess,
  }
}

const filterLinks = (links, access) => {
  const {
    isBaltoAdmin,
    isHierarchyManager,
    hasGamificationAccess,
    hasRTCAccess,
    hasCopilotAccess,
    hasRTQAccess,
    hasDisputesAccess,
    hasNotetakerAccess,
    hasLiveListenAccess,
  } = access

  const filteredLinks = links.filter((link) => {
    // Available to all users
    if (
      link.path.startsWith('/reports') ||
      link.path.startsWith('/playbooks') ||
      link.path.startsWith('/leaderboards') ||
      link.path.startsWith('/playlists') ||
      link.path.startsWith('/call-explorer')
    ) {
      return true
    }

    // Balto Admin only
    if (
      link.path === '/organizations' ||
      link.path === '/qa-copilot/inbox' ||
      link.path === '/compliance/inbox' ||
      link.path === '/coaching/inbox' ||
      link.path === '/insights'
    ) {
      return isBaltoAdmin
    }

    // Users only
    if (link.path === '/users') {
      return !isBaltoAdmin
    }

    if (link.path === '/command-center') {
      return isBaltoAdmin || hasLiveListenAccess
    }

    // I don't think this does anything, all users have gamification enabled
    if (link.path.startsWith('/gamification')) {
      return isBaltoAdmin || hasGamificationAccess
    }

    // RTC
    if (link.path.startsWith('/realtime_coaching')) {
      return isBaltoAdmin || hasRTCAccess
    }

    // Copilot
    if (link.path === '/qa-copilot/enterprise') {
      return isBaltoAdmin || isHierarchyManager
    }

    if (link.path === '/qa-copilot/disputes') {
      return isBaltoAdmin || (hasCopilotAccess && hasDisputesAccess)
    }

    if (link.path.startsWith('/qa-copilot')) {
      return isBaltoAdmin || hasCopilotAccess
    }

    // RTQ
    if (link.path === '/scorecards/disputes') {
      return isBaltoAdmin || (hasRTQAccess && hasDisputesAccess)
    }

    if (link.path.startsWith('/scorecards')) {
      return isBaltoAdmin || hasRTQAccess
    }

    if (link.path.startsWith('/realtime-notetaker')) {
      return isBaltoAdmin || hasNotetakerAccess
    }

    return isBaltoAdmin
  })

  return filteredLinks
}

export const getSidebarData = (currentOrganization, currentUser, flags) => {
  const access = getAccess(currentUser, currentOrganization, flags)
  const calls = filterLinks(SIDEBAR.callsLinks, access)
  const quality = filterLinks(SIDEBAR.qualityLinks, access)
  const guidance = filterLinks(SIDEBAR.guidanceLinks, access)
  const coaching = filterLinks(SIDEBAR.coachingLinks, access)
  const compliance = filterLinks(SIDEBAR.complianceLinks, access)
  const admin = filterLinks(SIDEBAR.adminLinks, access)
  const dataExport = filterLinks(SIDEBAR.dataExportLinks, access)

  return {
    [SIDEBAR.CALLS]: { title: 'Calls', links: calls },
    [SIDEBAR.COACHING]: { title: 'Coaching', links: coaching, noCollapse: true },
    [SIDEBAR.COMPLIANCE]: { title: 'Compliance', links: compliance, noCollapse: true },
    [SIDEBAR.QUALITY]: { title: 'Quality', links: quality },
    [SIDEBAR.GUIDANCE]: { title: 'Guidance', links: guidance },
    [SIDEBAR.ADMIN]: { title: 'Admin', links: admin },
    [SIDEBAR.DATA_EXPORT]: { title: 'Data Export', links: dataExport },
  }
}
