import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'
import { Loader } from 'semantic-ui-react'
import { withLDProvider } from 'launchdarkly-react-client-sdk'

import { Toasts } from '@/components/toasts/Toasts'
import { BaltoGPT } from '@/components/baltoGPT/BaltoGPT'

import store from './store'
import App from './App'
import { buildUserAttributes } from './utils/launchDarkly'
import ErrorBoundary from './components/layout/ErrorBoundary'
import RtcSocketProvider from './components/providers/RtcSocketProvider'
import OrganizationsProvider from './components/providers/OrganizationsProvider'
import CustomBrandingProvider from './components/providers/CustomBrandingProvider'
import UserPermissionsProvider from './components/providers/UserPermissionsProvider'

import './assets/styles/variables.scss' // All base variables that the rest of the CSS pulls from
import './assets/styles/theme.scss' // HTML and Semantic UI overrides
import './assets/styles/main.scss' // TODO: Phase this file out
import './assets/styles/helpers.scss' // Helper classes

export const history = createBrowserHistory()

// Creating the portal here so we only make 1, and all the draggables can share it
if (document.body) {
  const portal = document.createElement('div')
  portal.classList.add('dnd-portal')
  document.body.appendChild(portal)
}
const currentAppVersion = process.env.COMMIT_HASH

console.info(`Version: ${currentAppVersion}`)

function renderApp(NextApp) {
  const user = JSON.parse(localStorage.getItem('User'))
  const ldUser = user ? buildUserAttributes(user) : { key: 'Anonymous' }

  // set user identity for FullStory
  if (window.FS && user) {
    window.FS('setIdentity', {
      uid: user.user_id,
      properties: {
        user: {
          email: user.username,
          firstName: user.first_name,
          lastName: user.last_name,
        },
        organization: {
          name: user.organization_name,
          id: user.organizationid,
        },
      },
    })
  }

  const launchDarklyConfig = {
    clientSideID: process.env.LAUNCH_DARKLY_CLIENTSIDE_ID,
    user: ldUser,
    options: {
      ...(process.env.LAUNCH_DARKLY_STREAMING_OPTION !== undefined && {
        streaming: process.env.LAUNCH_DARKLY_STREAMING_OPTION,
      }),
    },
  }

  const LDApp = withLDProvider(launchDarklyConfig)(() => (
    <Provider store={store}>
      <React.Suspense fallback={<Loader active />}>
        <ErrorBoundary>
          <RtcSocketProvider />
          <OrganizationsProvider />
          <CustomBrandingProvider />
          <UserPermissionsProvider />
          <Router history={history}>
            <Toasts />
            <BaltoGPT />
            {NextApp ? <NextApp /> : <App />}
          </Router>
        </ErrorBoundary>
      </React.Suspense>
    </Provider>
  ))

  ReactDOM.render(<LDApp />, document.getElementById('content') || document.createElement('div'))
}

renderApp()

// Enable Webpack hot module replacement for components
if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./App.js', () => {
    const nextApp = require('./App').default
    renderApp(nextApp)
  })
}
