import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Segment, Dropdown, Input, Button, Dimmer, Loader } from 'semantic-ui-react'

import {
  createNewVoipCampaign,
  fetchOrganizationVoipCampaigns,
  deleteOrganizationsVoipCampaign,
} from '@/reducers/organizations/organizationVoipCampaigns.actions'

import { AdvancedTable } from '../../components/tables/AdvancedTable/AdvancedTable'
import Form from '../../components/Modal'
import ConfirmForm from '../../components/forms/ConfirmForm'

const columns = [
  { accessor: 'campaignName', isSearchable: true, label: 'VoIP Campaign Name' },
  { accessor: 'campaignConfigName', isSearchable: true, label: 'Playbook' },
  { accessor: 'deleteAction', label: 'Delete' },
]

const VoipCampaignForm = () => {
  const dispatch = useDispatch()
  const { organizationid } = useParams()
  const configs = useSelector((state) => state.configs)
  const voipCampaigns = useSelector((state) => state.organizationVoipCampaigns.voipCampaigns)

  const [inputText, setInputText] = useState('')
  const [loading, setLoading] = useState(false)
  const [selectedConfigCid, setSelectedConfigCid] = useState('')

  const updateInputText = (event, data) => setInputText(data.value)
  const updateSelectedConfigCid = (event, data) => setSelectedConfigCid(data.value)

  const handleFetchOrganizationVoipCampaigns = () => {
    dispatch(fetchOrganizationVoipCampaigns({ organizationId: organizationid, setLoading }))
  }

  useEffect(() => {
    handleFetchOrganizationVoipCampaigns()
  }, [])

  const handleCreateNewVoipCampaign = () => {
    dispatch(
      createNewVoipCampaign({
        cid: selectedConfigCid,
        campaignName: inputText,
        organizationId: organizationid,
        setLoading,
        reloadOrganizationsVoipCampaigns: handleFetchOrganizationVoipCampaigns,
        clearForm: () => {
          setInputText('')
          setSelectedConfigCid('')
        },
      })
    )
  }

  const handleDeleteOrganizationsVoipCampaign = (voipCampaignUuid) => {
    dispatch(
      deleteOrganizationsVoipCampaign({
        voipCampaignUuid,
        organizationId: organizationid,
        reloadOrganizationsVoipCampaigns: handleFetchOrganizationVoipCampaigns,
        setLoading,
      })
    )
  }

  const dropdownOptions = configs
    // NOTE: we do not filter for active playbooks because that would exclude playbooks in "draft mode" (latest version is not published)
    // `configs` stores the most recent version of all non-deleted configs, so latest version may be inactive
    .filter((config) => Number(config.organization_id) === Number(organizationid))
    .map((config) => ({ key: config.cid, text: config.name, value: config.cid }))

  const formattedVoipCampaigns = voipCampaigns.map((campaign) => {
    const [campaignConfig] = configs.filter((config) => config.cid === campaign.config_cid)
    // the order of this array here must match the headers
    return {
      campaignName: campaign?.name || 'Missing Campaign Name',
      // if we don't find playbook because it's deleted show placeholder
      campaignConfigName: campaignConfig?.name || 'Deleted Playbook',
      deleteAction: (
        <Form
          header={`Delete ${campaign.name}`}
          icon="trash"
          muted
          modalSize="tiny"
          form={
            <ConfirmForm
              valueToSubmit={campaign.uuid}
              bodyText="Are you sure you want to delete this VoIP campaign? This action cannot be undone."
              primaryButtonText="Delete"
              handleSubmit={() => handleDeleteOrganizationsVoipCampaign(campaign.uuid)}
              danger
            />
          }
        />
      ),
    }
  })

  return (
    <Dimmer.Dimmable>
      <div data-testid="manage-voip-campaigns-form">
        <Segment className="not-padded mb">
          <AdvancedTable rows={formattedVoipCampaigns} columns={columns} pagination />
        </Segment>
      </div>
      <Input
        fluid
        action
        type="text"
        value={inputText}
        maxLength={100}
        onChange={updateInputText}
        placeholder="VoIP Campaign Name..."
      >
        {/* Multiple Actions require children. When using children,
          you must add a placeholder <input />. */}
        <input />
        <Dropdown
          search
          selection
          options={dropdownOptions}
          value={selectedConfigCid}
          placeholder="Playbook name"
          onChange={updateSelectedConfigCid}
        />
        <Button
          primary
          type="submit"
          onClick={handleCreateNewVoipCampaign}
          // if either value is empty we disable save button
          disabled={!inputText || !selectedConfigCid}
        >
          Save
        </Button>
      </Input>
      {loading && (
        <Dimmer active inverted>
          <Loader active />
        </Dimmer>
      )}
    </Dimmer.Dimmable>
  )
}

export default VoipCampaignForm
