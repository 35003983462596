import React, { useState, useRef } from 'react'
import { Popup, Button } from 'semantic-ui-react'
import classNames from 'classnames'

import { MoreActionsIcon } from '@/components/icons/MoreActionsIcon'

import './TableActionsDropdown.scss'

export const TableActionsDropdown = ({ triggerLabel, icon, buttonProps = {}, actions = [] }) => {
  const [isOpen, setIsOpen] = useState(false)
  const triggerRef = useRef(null)

  return (
    <Popup
      open={isOpen}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      on="click"
      position="bottom right"
      basic
      trigger={
        <Button
          ref={triggerRef}
          active={isOpen}
          basic
          icon
          className="svg-button"
          data-testid="table-actions-dropdown"
          {...buttonProps}
        >
          {triggerLabel}
          {icon || <MoreActionsIcon />}
        </Button>
      }
      className="table-actions-popup"
    >
      <div className="table-actions-menu" role="menu">
        {actions.map(({ label, onClick, testId, isDanger }) => (
          <Button
            key={label || testId}
            fluid
            basic
            className={classNames({ danger: isDanger })}
            onClick={() => {
              onClick()
              setIsOpen(false)
            }}
            data-testid={testId}
            role="menuitem"
          >
            {label}
          </Button>
        ))}
      </div>
    </Popup>
  )
}
