import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Checkbox, Loader } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import {
  fetchPlaybooks,
  getPlaybookPhraseSuggestions,
  setMassPlaybookUpdateChecklistSelection,
} from '@/reducers/playbooks/playbooks.actions'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import { Banner } from '@/components/banners/Banner'
import { IconAlertCircle } from '@tabler/icons-react'
import { CheckboxWrap } from './ChecklistForm'

const columns = [
  {
    label: 'Matching Content',
    accessor: 'content',
    format: (value) => (
      <div style={{ maxWidth: '400px', whiteSpace: 'initial' }}>
        <span style={{}}>{value}</span>
      </div>
    ),
  },
  {
    label: 'Playbook',
    accessor: 'config_name',
    format: (value, row) => (
      <div
        style={{
          maxWidth: '200px',
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        <a href={`/playbooks/${row.config_id}/checklist`} target="_blank" rel="noreferrer">
          {value}
        </a>
      </div>
    ),
  },
]

const ChecklistBulkEdit = () => {
  const dispatch = useDispatch()
  const { id: config_id, entryId } = useParams()

  const { playbook } = useSelector((state) => state.playbook)
  const { loading, massPlaybookUpdateMatches, massPlaybookUpdateSelection, playbooks } =
    useSelector((state) => state.playbooks)

  const [bulkEditOn, setBulkEditOn] = useState(false)

  // Preload all organization playbooks (for Mass Playbook Update)
  useEffect(() => {
    dispatch(fetchPlaybooks(playbook.organization_id, true))
  }, [config_id])

  useEffect(() => {
    if (bulkEditOn && !isEmpty(playbooks)) {
      const configs = {}
      playbooks.forEach((p) => {
        configs[p.id] = {
          name: p.name,
          body: {
            checklist: p.body.checklist,
          },
        }
      })
      const target_item = { config_id, uuid: entryId }
      const payload = { data: { ndarray: [{ configs, target_item }] } }
      dispatch(getPlaybookPhraseSuggestions(payload))
    }
  }, [bulkEditOn, playbooks])

  const handleBulkSelectSingle = (content_id) => {
    if (!massPlaybookUpdateSelection.includes(content_id)) {
      dispatch(
        setMassPlaybookUpdateChecklistSelection([...massPlaybookUpdateSelection, content_id])
      )
    } else {
      dispatch(
        setMassPlaybookUpdateChecklistSelection(
          massPlaybookUpdateSelection.filter((selectedId) => selectedId !== content_id)
        )
      )
    }
  }

  const handleSelectAll = (rows) => {
    dispatch(setMassPlaybookUpdateChecklistSelection(rows.map((row) => row.content_id)))
  }

  const handleDeselectAll = () => {
    dispatch(setMassPlaybookUpdateChecklistSelection([]))
  }

  return (
    <>
      <CheckboxWrap
        label="Edit in Bulk Across Playbooks (Alpha)"
        text="Let the system identify similar content across your Playbooks, allowing you to select and edit multiple items simultaneously. Please note, this is an experimental feature."
      >
        <div>
          <Checkbox checked={bulkEditOn} onChange={() => setBulkEditOn(!bulkEditOn)} toggle />
        </div>
      </CheckboxWrap>
      {bulkEditOn && (
        <div className="mb" style={{ maxWidth: '715px' }}>
          <Banner
            caution
            className="mb"
            header="Proceed with Caution"
            icon={<IconAlertCircle />}
            data-testid="mass-playbook-edit-banner"
          >
            <div className="mbp">
              This experimental feature updates content across multiple Playbooks at once but does
              not keep them synchronized to ensure content independence.
            </div>
            <div className="mbp">
              Once you press “Publish,” this option will turn itself off, and the changes will apply
              to the current Playbook as well as all selected Playbooks listed below.
            </div>
          </Banner>
          {loading ? (
            <Loader active inline="centered" size="massive" />
          ) : (
            <AdvancedTable
              columns={columns}
              rows={massPlaybookUpdateMatches}
              selectedRows={massPlaybookUpdateSelection}
              index="content_id"
              bulkSelectable
              bulkSelectAction={<></>}
              handleBulkSelectSingle={handleBulkSelectSingle}
              handleDeselectAll={handleDeselectAll}
              handleSelectAll={handleSelectAll}
              itemName="checklist"
            />
          )}
        </div>
      )}
    </>
  )
}

export default ChecklistBulkEdit
