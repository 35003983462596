import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Loader } from 'semantic-ui-react'

import { closeModal } from '@/reducers/ui/ui.redux'
import { AbstractForm } from '@/components/forms/formik/AbstractForm'
import {
  formatSavedFilterFormExistingValues,
  getSavedFilterFormDefaultValues,
} from '@/reducers/savedFilters/savedFilters.helpers'
import {
  saveFilter,
  updateSavedFilter,
  fetchFilterFormData,
  fetchCategoriesForFilters,
} from '@/reducers/savedFilters/savedFilters.actions'

import { buildSchema } from './SavedFilterForm.schema'

export const SavedFilterForm = ({ selectedFilter, filterFormOptions, filterType }) => {
  const dispatch = useDispatch()
  const { data, loading } = useSelector((state) => state.savedFilters)
  const {
    organizationid: currentUserOrgId,
    edit_qa: editQA,
    edit_qa_copilot: editQACopilot,
  } = useSelector((state) => state.currentUser)
  const { qa_enabled: qaEnabled, qa_copilot_access: qaCopilotAccess } = useSelector(
    (state) => state.currentOrganization
  )
  const scorecardFiltersHidden =
    currentUserOrgId !== 1 && !((qaEnabled && editQA) || (qaCopilotAccess && editQACopilot))

  if (scorecardFiltersHidden) {
    filterFormOptions = filterFormOptions.filter(
      (option) =>
        !(
          option.includes('scorecards') ||
          option.includes('scorecardThreshold') ||
          option.includes('scoredStatus')
        )
    )
  }
  const existingValues = selectedFilter
    ? formatSavedFilterFormExistingValues(selectedFilter, filterType)
    : getSavedFilterFormDefaultValues(filterType)

  const handlePlaybookChangeSideEffect = (option) => {
    if (filterFormOptions.includes('postcallCategories')) {
      dispatch(
        fetchCategoriesForFilters(
          existingValues.organization || currentUserOrgId,
          'postcall',
          option
        )
      )
    }

    if (filterFormOptions.includes('deckCategories')) {
      dispatch(
        fetchCategoriesForFilters(existingValues.organization || currentUserOrgId, 'deck', option)
      )
    }
  }

  const handleClose = () => {
    dispatch(closeModal())
  }

  const handleSubmit = async (filter, { setSubmitting }) => {
    if (selectedFilter) {
      await dispatch(updateSavedFilter(selectedFilter.uuid, filter, filterType))
    } else {
      await dispatch(saveFilter(filter, filterType))
    }

    setSubmitting(false)
  }

  const schema = buildSchema(
    data,
    loading,
    filterFormOptions,
    handlePlaybookChangeSideEffect,
    existingValues
  )

  useEffect(() => {
    dispatch(fetchFilterFormData(currentUserOrgId, filterFormOptions, selectedFilter))
  }, [])

  if (loading[filterType]) {
    return <Loader active />
  }

  return (
    <AbstractForm
      onSubmit={handleSubmit}
      existingValues={existingValues}
      schema={schema}
      buttonLabel="Save"
      onClose={handleClose}
      isModal
    />
  )
}
