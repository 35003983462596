import { useState } from 'react'

export const usePagination = (array = [], rowsPerPage = 10) => {
  const [activePage, setActivePage] = useState(1)

  const count = array.length
  const calculatedRowsPerPage = rowsPerPage
  const totalPages = Math.ceil(count / calculatedRowsPerPage)
  const paginatedArray = array.slice(
    (activePage - 1) * calculatedRowsPerPage,
    activePage * calculatedRowsPerPage
  )
  const pagination = {
    activePage,
    setActivePage,
    paginatedArray,
    totalPages,
    count,
    rowsPerPage: calculatedRowsPerPage,
  }

  return pagination
}
