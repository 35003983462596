import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Divider, Form } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import { Field } from 'formik'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { IconAlertTriangle } from '@tabler/icons-react'

import { Checkbox, NumberField, Select, TextArea } from '@/components/forms/formik'
import { Banner } from '@/components/banners/Banner'
import { PlaybookEntryFormTriggers } from '@/views/Playbooks/components/PlaybookEntryFormTriggers'
import pluralize from 'pluralize'
import { PlaybookFormActions } from '../../components/PlaybookFormActions'
import { FieldWarning } from '../../components/FieldWarning'
import { FormObserver } from '../../components/FormObserver'
import { accessors, checklistTriggerTypes } from '../../playbook.helpers'
import ChecklistBulkEdit from './ChecklistBulkEdit'

export const CheckboxWrap = ({ label, text, children }) => (
  <div className="flex-align-center flex-space-between gap mb">
    <div className="flex-column">
      <div className="label">{label}</div>
      <div>{text}</div>
    </div>
    {children}
  </div>
)

export const ChecklistFormComponent = ({
  isCreate,
  handleSubmit,
  handleCancel,
  errors,
  entryId,
  readOnly,
  flags,
  values,
  initialValues,
  ...formikProps
}) => {
  const dispatch = useDispatch()
  const {
    playbook: { mostRecentId, language },
    loading,
  } = useSelector((state) => state.playbook)
  const { org_realtime_coaching_access: hasRtcAccess, organizationid } = useSelector(
    (state) => state.currentUser
  )
  const submitDisabled = readOnly || !isEmpty(errors)
  const showOutdatedPlaybookWarning = isCreate && readOnly
  const updatedTriggerTypeOptions =
    flags?.smartPhrasesTriggerType && language === 'english'
      ? [...checklistTriggerTypes, { label: 'Smart Phrases', value: 'smart_phrases' }]
      : checklistTriggerTypes

  // We store everything in seconds. Let's start by converting it on load.
  useEffect(() => {
    const { trigger } = values

    if (trigger?.occurrence_required) {
      // Anything under 60 seconds will always be represented as seconds
      let timeValue = trigger.required_before
      let timeUnit = 'seconds'

      // When it's cleanly divided by 60 let's use minutes
      if (timeValue >= 60 && timeValue % 60 === 0) {
        timeValue /= 60
        timeUnit = 'minutes'
      } else if (timeValue >= 60) {
        // If it's not cleanly divisible, calculate minutes as a float to two decimal places
        const minutes = Math.floor(timeValue / 60)
        const seconds = timeValue % 60
        timeValue = minutes + seconds / 60
        timeUnit = 'minutes'
      }

      formikProps.setFieldValue('trigger.required_before', timeValue)
      formikProps.setFieldValue('trigger.required_before_time_unit', timeUnit)
    }
  }, [])

  // Not used with checklist
  const updateTriggerWithAi = (krid) => {
    formikProps.setFieldValue('trigger.type', 'transcription_classifier')
    formikProps.setFieldValue('trigger.krid', krid)
  }

  // Not used with checklist
  const clearTriggerValues = ({ value }) => {
    formikProps.setFieldValue('trigger.phrases', undefined)
    formikProps.setFieldValue('trigger.krid', undefined)
    formikProps.setFieldValue('trigger.ratio', undefined)
    formikProps.setFieldValue('trigger.side', undefined)
    // A previous value is being validated after these changes if we don't update here
    formikProps.setFieldValue('trigger.type', value)
    formikProps.setFieldValue('trigger.required_before_time_unit', 'seconds')
  }

  const calculateTimeInSeconds = (timeValue, timeUnit) => {
    // We store everything in seconds on the backend
    if (timeUnit === 'minutes') {
      timeValue *= 60
    }

    return timeValue
  }
  const buildTimeLabel = (values) => {
    const { required_before: timeValue, required_before_time_unit: timeUnit } = values.trigger
    // Handle poor user input, or lack thereof
    if (timeValue === undefined || timeValue === '') {
      return 'Enter a number'
    }
    if (timeValue < 1) {
      return 'Please give the agent a moment to complete this item'
    }
    if (timeUnit === undefined || timeUnit === '') {
      return 'Select a unit of time'
    }

    const timeInSeconds = calculateTimeInSeconds(timeValue, timeUnit)
    const minutes = Math.floor(timeInSeconds / 60)
    // Because 1.5 minutes is a thing
    const seconds = Math.floor(timeInSeconds % 60)

    let humanReadableTime = 'This item will be required '

    if (minutes > 0) {
      humanReadableTime += `${pluralize('minute', Number(minutes), true)}`
    }
    if (minutes > 0 && seconds > 0) {
      humanReadableTime += ' and '
    }
    if (seconds > 0) {
      humanReadableTime += `${pluralize('second', Number(seconds), true)}`
    }

    humanReadableTime += ' after the start of the call'
    return humanReadableTime
  }

  const convertAndSubmit = () => {
    const { trigger } = values

    if (trigger.occurrence_required) {
      const { trigger } = values

      if (trigger?.required_before_time_unit === 'minutes') {
        // Gotta convert minutes to seconds for the backend
        // We store ints in the backend, so while 4.5min will multiple and save OK, 4.52343 will not
        const seconds = trigger?.required_before * 60 || 0
        formikProps.setFieldValue('trigger.required_before', Math.floor(seconds))
      } else {
        formikProps.setFieldValue('trigger.required_before', Math.floor(trigger.required_before))
      }
    }

    handleSubmit()
  }

  return (
    <div className="playbook-detail__content">
      <Form onSubmit={convertAndSubmit}>
        <FormObserver entryId={entryId} sectionName={accessors.CHECKLIST} />
        {showOutdatedPlaybookWarning && (
          <Banner warning icon={<IconAlertTriangle />} header="Outdated Playbook Version">
            The playbook version you are trying to edit is out of date. To add a checklist item,
            please use the <a href={`/playbooks/${mostRecentId}/checklist`}>most recent version</a>{' '}
            of this playbook.
          </Banner>
        )}
        <Field label="Previous Value" value={initialValues.name} component={TextArea} disabled />
        <Field
          required
          label="New Value"
          name="name"
          component={TextArea}
          disabled={readOnly}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              event.preventDefault()
            }
          }}
        />
        {!readOnly && <FieldWarning accessor="checklist" field="name" entryId={entryId} />}
        {hasRtcAccess && (
          <>
            <h2>Checklist Item Configuration</h2>
            <CheckboxWrap
              label="Add Time Requirement"
              text="Remind agents to say this item within a specified time (e.g., a few seconds or minutes) after the call starts. This feature is ideal for ensuring compliance with mandatory scripts."
            >
              <Field
                name="trigger.occurrence_required"
                component={Checkbox}
                toggle
                inline
                reverse
                disabled={readOnly}
              />
            </CheckboxWrap>
            {values.trigger?.occurrence_required && (
              <>
                <div className="required-item">
                  <Field
                    className="time-value"
                    current={!readOnly}
                    name="trigger.required_before"
                    component={NumberField}
                    onChange={(e) =>
                      formikProps.setFieldValue(
                        'trigger.required_before',
                        calculateTimeInSeconds(e.target.value)
                      )
                    }
                  />
                  <Field
                    current={!readOnly}
                    required
                    name="trigger.required_before_time_unit"
                    component={Select}
                    options={[
                      { label: 'Minutes', value: 'minutes' },
                      { label: 'Seconds', value: 'seconds' },
                    ]}
                    placeholder="Unit of Time"
                  />
                </div>
                <div style={{ marginBottom: '1rem' }}>{buildTimeLabel(values)}</div>
              </>
            )}
          </>
        )}
        <CheckboxWrap
          label="Prevent Variants"
          text="Restrict agents from creating variations or modifying this item. We recommend keeping this option off unless required for compliance."
        >
          <Field name="is_locked" component={Checkbox} toggle inline reverse disabled={readOnly} />
        </CheckboxWrap>
        {flags?.playbookChecklistTriggerTypeEnabled && (
          <>
            <Divider horizontal>Trigger</Divider>
            <Field
              required
              label="Trigger Type"
              name="trigger.type"
              component={Select}
              options={updatedTriggerTypeOptions}
              isClearable={false}
              disabled={readOnly}
              onChange={clearTriggerValues}
            />
            <PlaybookEntryFormTriggers
              loading={loading}
              values={values}
              dispatch={dispatch}
              triggerType={values.trigger?.type}
              formikProps={formikProps}
              readOnly={readOnly}
              sideHidden
              triggerHidden
              accessor={accessors.CHECKLIST}
              entryId={entryId}
              updateTriggerWithAi={updateTriggerWithAi}
            />
          </>
        )}
        {/* TEMPRARELY HIDING (MPU) FOR NON-BALTO ORGs - WHILE IN DEVELOPMENT */}
        {organizationid === 1 && <ChecklistBulkEdit />}
        <PlaybookFormActions
          isDisabled={submitDisabled}
          handleCancel={handleCancel}
          dirty={formikProps.dirty}
        />
      </Form>
    </div>
  )
}

export const ChecklistForm = withLDConsumer()(ChecklistFormComponent)
