import produce from 'immer'

import * as TASK_TYPES from './tasks.constants'

// Action Types
export const SET_TASK = 'tasks/setTask'
export const SET_TASKS = 'tasks/setTasks'
export const SET_LOADING = 'tasks/setLoading'
export const SET_ERROR = 'tasks/setError'

// Action Creators
export const setTask = (payload) => ({ type: SET_TASK, payload })
export const setTasks = (payload) => ({ type: SET_TASKS, payload })
export const setLoading = (payload) => ({ type: SET_LOADING, payload })
export const setError = (payload) => ({ type: SET_ERROR, payload })

// State
export const initialState = {
  tasks: [],
  task: null, // Warning: cobbled together JSONB nonsense
  loading: {
    task: false,
    [TASK_TYPES.INBOX]: false,
  },
  error: {
    task: false,
    [TASK_TYPES.INBOX]: false,
  },
}

// Reducer
export default function tasksRedux(state = initialState, action) {
  return produce(state, (newState) => {
    const { payload } = action

    switch (action.type) {
      case SET_TASK:
        newState.task = payload
        break
      case SET_TASKS:
        newState.tasks = payload
        break
      case SET_LOADING:
        newState.loading = { ...state.loading, ...payload }
        break
      case SET_ERROR:
        newState.data = { ...state.data, ...payload }
        break
      default:
        break
    }
  })
}
