import React from 'react'
import {
  IconCalendar,
  IconCircleDot,
  IconCircleDashedCheck,
  IconCircleCheckFilled,
  IconTag,
  IconUser,
} from '@tabler/icons-react'
import { Divider } from 'semantic-ui-react'
import moment from 'moment'

import { Pill } from '@/components/pills/Pill'

import { formatTaskStatus, formatTaskType } from './helpers'

export const Metadata = ({ status, agent, type, createdAt, scoreEvidence }) => {
  const dataTestId = 'task-drawer-metadata'
  const createdStr = moment.utc(createdAt).local()

  const formattedTaskPill = () => {
    const taskType = formatTaskType(type, scoreEvidence)
    return (
      <Pill
        dataTestId={`${dataTestId}-task-type`}
        xs
        magical={taskType === 'Copilot Escalation'}
        warning={taskType === 'Pending Evaluation'}
        critical={taskType === 'Dispute'}
        emphasized={taskType === 'Coaching'}
        bold
        className="uppercase"
      >
        {taskType}
      </Pill>
    )
  }

  return (
    <>
      <table className="key-value-table" data-testid={dataTestId}>
        <thead>
          <tr>
            <td>
              <div className="flex-align-center small-gap">
                <IconCircleDot className="icon-svg" />
                <strong>Status</strong>
              </div>
            </td>
            <td>
              <div className="flex-align-center small-gap" data-testid={`${dataTestId}-status`}>
                {status !== ('completed' || 'ignored') ? (
                  <IconCircleDashedCheck
                    className="icon-svg"
                    data-testid={`${dataTestId}-dashed-check`}
                  />
                ) : (
                  <IconCircleCheckFilled
                    data-testid={`${dataTestId}-filled-check`}
                    className="status-success"
                  />
                )}
                {formatTaskStatus(status)}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className="flex-align-center small-gap">
                <IconUser className="icon-svg" />
                <strong>Agent</strong>
              </div>
            </td>
            <td data-testid={`${dataTestId}-agent`}>{agent}</td>
          </tr>
          <tr>
            <td>
              <div className="flex-align-center small-gap">
                <IconTag className="icon-svg" />
                <strong>Type</strong>
              </div>
            </td>
            <td>{formattedTaskPill()}</td>
          </tr>
          <tr>
            <td>
              <div className="flex-align-center small-gap">
                <IconCalendar className="icon-svg" />
                <strong>Date Created</strong>
              </div>
            </td>
            <td data-testid={`${dataTestId}-date`}>{createdStr.format('MM/DD/YYYY')}</td>
          </tr>
        </thead>
      </table>

      <Divider />
    </>
  )
}
