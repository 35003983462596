import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormField, Form, TextArea } from 'semantic-ui-react'
import { isNumber, isEmpty, startCase } from 'lodash'

import { COMPLETED } from '@/reducers/tasks/tasks.constants'

import { resolveDispute } from '@/reducers/callSearch/callSearch.actions'
import { TaskDrawerFooter } from './TaskDrawerFooter'
import { ScoreForm } from './ScoreForm'

export const DisputeDrawer = ({
  agent,
  task,
  handleClose,
  handlePrimaryAction,
  handleSecondaryAction,
}) => {
  const [score, setScore] = useState(0)
  const [comment, setComment] = useState('')
  const dispatch = useDispatch()
  const { user_id: userId } = useSelector((state) => state.currentUser)

  if (isEmpty(task.score_evidence)) {
    return null
  }

  const isCompleted = task.status === COMPLETED
  const { name: criteria, score: currentScore, dispute, criteria_score_id } = task.score_evidence[0]
  const originalScore = isNumber(dispute.previous_score) ? dispute.previous_score : currentScore

  const getScoreLabel = () => {
    switch (originalScore) {
      case 1:
        return 'Passed'
      case 0:
        return 'Failed'
      case -1:
        return 'N/A'
      default:
        return 'Invalid Score'
    }
  }

  const handleResolveDispute = async () => {
    const resolvedDispute = {
      dispute: {
        ...dispute,
        score,
        manager_comment: comment,
        manager_id: userId,
        scorecard_criteria_scores_id: criteria_score_id,
        id: dispute.dispute_id,
        status: originalScore === score ? 'declined' : 'accepted',
      },
    }
    await dispatch(resolveDispute(resolvedDispute, userId))
  }

  useEffect(() => {
    setScore(currentScore)
    setComment(dispute.manager_comment)
  }, [])

  return (
    <>
      <h3>Dispute Comment</h3>
      <div className="dispute-comment">
        <p data-testid="dispute-comment">
          {agent}: {dispute.user_comment}
        </p>
        <p className="muted-text" data-testid="dispute-original-score">
          Original Score: {getScoreLabel()}
        </p>
      </div>

      <h3>Disputed Criteria</h3>
      <p data-testid="dispute-criteria">{criteria}</p>
      <ScoreForm
        baseDataTestId="dispute"
        isDisabled={isCompleted}
        score={score}
        setScore={setScore}
      />
      <Form>
        <FormField className="mb">
          {!isEmpty(dispute.manager_comment) && isCompleted ? (
            <div className="mb">
              <h3 data-testid="dispute-status">Manager {startCase(dispute.status)} Dispute</h3>
              <div className="dispute-comment mb">
                <p data-testid="dispute-manager-comment">{dispute.manager_comment}</p>
              </div>
            </div>
          ) : (
            <TextArea
              placeholder="Enter your comment here"
              className="mb"
              data-testid="dispute-comment-textarea"
              onChange={(event) => setComment(event.target.value)}
              value={comment}
              disabled={isCompleted}
            />
          )}
        </FormField>
      </Form>
      <TaskDrawerFooter
        handleClose={handleClose}
        handlePrimaryAction={async () => {
          if (!isCompleted) {
            await handleResolveDispute()
          }
          handlePrimaryAction(task.id, handleClose)
        }}
        handleSecondaryAction={
          isCompleted ? null : () => handleSecondaryAction(task.id, handleClose)
        }
        primaryActionButtonText={isCompleted ? 'Reopen Task' : undefined}
      />
    </>
  )
}
