import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { toLower, isEmpty } from 'lodash'
import moment from 'moment'

import { Button, TextArea, Form, Popup } from 'semantic-ui-react'
import { IconPencil, IconTrash } from '@tabler/icons-react'

export const Comment = ({
  commentData,
  associatedId,
  updateComment,
  commentType,
  commentProperty = 'comment',
}) => {
  const formatDate = (date) => moment.utc(date).local().format('MMMM Do, h:mm A')
  const [isEditing, setIsEditing] = useState(false)
  const [editCommentText, setEditCommentText] = useState('')
  const { user_id: currentUserId } = useSelector((state) => state.currentUser)

  const { id, author, created_at, edited_at, user_id } = commentData
  const commentText = commentData[commentProperty] || ''
  const timeText = `${formatDate(created_at)}${edited_at ? ' (edited)' : ''}`
  const updateCommentLabel = `Update ${commentType}`

  return (
    <div className="comment" data-testid={`comment-${id}`}>
      <header className="comment-header gap flex-align-center flex-space-between full-width">
        <div className="medium-gap flex-align-center">
          <div className="comment-author" data-testid={`comment-author-${id}`}>
            {author}
          </div>
          <small className="comment-date" data-testid={`comment-timestamp-${id}`}>
            <i>{timeText}</i>
          </small>
        </div>
        {currentUserId === user_id && (
          <div className="flex-align-center">
            <Popup
              inverted
              content={`Edit ${commentType}`}
              trigger={
                <Button
                  compact
                  icon
                  basic
                  className="svg-button"
                  data-testid={`comment-edit-button-${id}`}
                  onClick={(event) => {
                    event.stopPropagation()
                    setIsEditing(true)
                    setEditCommentText(commentText)
                  }}
                >
                  <IconPencil />
                </Button>
              }
            />

            <Popup
              inverted
              content={`Delete ${commentType}`}
              trigger={
                <Button
                  compact
                  icon
                  basic
                  className="svg-button"
                  data-testid={`comment-delete-button-${id}`}
                  onClick={(event) => {
                    event.stopPropagation()
                    updateComment({ deleted: true }, id, associatedId, true)
                  }}
                >
                  <IconTrash />
                </Button>
              }
            />
          </div>
        )}
      </header>
      {isEditing ? (
        <Form className="full-width">
          <TextArea
            data-testid={`comment-textarea-${id}`}
            className="mb"
            style={{ minHeight: '150px' }}
            placeholder={`Enter a ${toLower(commentType)}...`}
            value={editCommentText}
            onKeyDown={(event) => event.stopPropagation()}
            onChange={(event) => setEditCommentText(event.target.value)}
            autoFocus
          />
          <div className="flex-end medium-gap">
            <Button
              data-testid={`comment-discard-${id}`}
              secondary
              onClick={() => {
                setEditCommentText(commentText)
                setIsEditing(false)
              }}
            >
              Discard
            </Button>
            <Button
              type="submit"
              data-testid={`comment-save-${id}`}
              primary
              onClick={() => {
                updateComment({ [commentProperty]: editCommentText.trim() }, id, associatedId)
                setIsEditing(false)
              }}
              disabled={editCommentText === commentText || isEmpty(editCommentText)}
            >
              {updateCommentLabel}
            </Button>
          </div>
        </Form>
      ) : (
        <p className="comment-content" data-testid={`comment-content-${id}`}>
          {commentText}
        </p>
      )}
    </div>
  )
}
