import React, { useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Segment, Button } from 'semantic-ui-react'
import {
  IconCloud,
  IconCloudFilled,
  IconPencil,
  IconClipboard,
  IconLockSquareRoundedFilled,
  IconLockOpen,
  IconUserPlus,
  IconUserMinus,
  IconMessageCircle2,
  IconMessageCircle2Filled,
  IconCircleCheckFilled,
  IconCheck,
  IconReload,
} from '@tabler/icons-react'
import classNames from 'classnames'

import { Pill } from '@/components/pills/Pill'
import { ButtonAndFormModal } from '@/components/layout/modals/ButtonAndFormModal'
import { AdvancedTable } from '@/components/tables/AdvancedTable/AdvancedTable'
import PasswordForm from '@/views/Organizations/PasswordForm'
import TemporaryLinkForm from '@/components/forms/TemporaryLinkForm'
import ConfirmForm from '@/components/forms/ConfirmForm'
import { reportPassphrase } from '@/reducers/organizations/organization.actions'
import { fetchUserAudit } from '@/reducers/organizations/organizationUsers.actions'

import { UserForm } from './UserForm'

export const UserTable = ({
  configs,
  editUsers,
  isAdmin,
  loading,
  qaEnabled,
  qaCopilotEnabled,
  tags,
  tagCategories,
  users,
  handleUserUpdate,
  generateTemporaryLink,
  generatePassphrase,
  orgRealtimeCoachingAccess,
  orgDesktopEnterpriseEnabled,
  userFilter,
  restoreUser,
  flags,
}) => {
  const dispatch = useDispatch()
  const currentUser = useSelector((state) => state.currentUser)
  const currentUserEmail = currentUser.username
  const { userId } = useSelector((state) => state.organizationUsers.userAuditSelected)

  const findTagCategoryForTag = (tag) => {
    return tagCategories.some((category) => category.id === tag.tag_category_id)
  }

  const tagCategoryColumns = tagCategories.map((category) => ({
    accessor: category.name,
    label: category.name,
    format: (tag) => {
      if (tag) {
        return (
          <Pill small emphasized key={tag.userId}>
            {tag.name}
          </Pill>
        )
      }

      return null
    },
  }))

  let columns = [
    {
      accessor: 'full_name',
      label: 'Name',
      isSearchable: true,
      sticky: true,
      format: (fullName, row) => (
        <div
          className="pointer"
          onClick={() => {
            dispatch(fetchUserAudit({ userId: row.id, fullName }))
          }}
          style={{
            fontWeight: userId === row.id ? 'bold' : 'initial',
          }}
        >
          {fullName}
        </div>
      ),
    },
    { accessor: 'username', isSearchable: true, label: 'Email' },
    { accessor: 'playbook', isSearchable: true, label: 'Playbook' },
    { accessor: 'code', isSearchable: true, label: 'VoIP User ID' },
  ]

  if (flags?.cloudTopAgentToggle2022) {
    columns.push({
      accessor: 'is_top_agent',
      label: 'Customization Enabled',
      format: (isTopAgent) => {
        return <div>{isTopAgent ? 'Yes' : 'No'}</div>
      },
    })
  }

  columns.push({
    accessor: 'uncategorized_tags',
    label: 'Uncategorized Tags',
    format: (tags) => {
      return tags.map((tag) => {
        if (tag) {
          return (
            <div key={`${tag.name}-${tag.userId}`}>
              {!findTagCategoryForTag(tag) && (
                <Pill small emphasized key={tag.userId}>
                  {tag.name}
                </Pill>
              )}
            </div>
          )
        }

        return null
      })
    },
  })

  const findPlaybookForUser = (user) => {
    const playbook = configs.find((config) => config.cid === user.config_cid)

    return playbook ? playbook.name : ''
  }

  const buildUncategorizedTagsForUser = (user) => {
    return user.tags.map((tag) => {
      if (!findTagCategoryForTag(tag)) {
        return {
          ...tag,
          userId: user.id,
          value: tag.name,
        }
      }

      return null
    })
  }

  const buildTagsForUser = (user) => {
    const mutatedUser = user
    mutatedUser.tags.forEach((tag) => {
      const foundTag = tagCategories.find((category) => category.id === tag.tag_category_id)
      if (foundTag) {
        mutatedUser[foundTag.name] = {
          ...tag,
          userId: user.id,
          value: tag.name,
        }
      }
    })

    return mutatedUser
  }

  const updateUserAccess = (user, accessType) => {
    handleUserUpdate({ ...user, [accessType]: !user[accessType] })
  }

  const commonActionProps = {
    popup: true,
    popupProps: { position: 'top right' },
    buttonProps: { compact: true, basic: true },
    modalProps: { size: 'tiny' },
  }

  const buildActionsForUser = () => {
    const actions = []

    // Edit User
    if (isAdmin || editUsers) {
      actions.push({
        label: 'Edit',
        trigger: (row) => {
          if (!row.deleted) {
            return (
              <ButtonAndFormModal
                {...commonActionProps}
                icon={<IconPencil />}
                modalTitle={`Edit ${row.full_name}`}
                modalId={`users/edit-user-${row.id}`}
                modalProps={{ size: 'small' }}
                form={
                  <UserForm
                    configs={configs}
                    user={row}
                    tags={tags}
                    tagCategories={tagCategories}
                    qaEnabled={qaEnabled}
                    qaCopilotEnabled={qaCopilotEnabled}
                    realtimeCoachingAccessEnabled={orgRealtimeCoachingAccess}
                    handleSubmitUser={handleUserUpdate}
                    orgDesktopEnterpriseEnabled={orgDesktopEnterpriseEnabled}
                    flags={flags}
                  />
                }
              />
            )
          }
          return null
        },
      })

      // Balto Cloud Access
      actions.push({
        label: 'Reset Password',
        trigger: (row) => {
          if (!row.deleted) {
            return (
              <ButtonAndFormModal
                {...commonActionProps}
                modalProps={{ size: 'small' }}
                modalId={`users/reset-password-${row.id}`}
                modalTitle={`Send Password Reset Email to ${row.full_name}`}
                icon={row.password ? <IconCloudFilled /> : <IconCloud />}
                form={
                  <TemporaryLinkForm
                    updateUser={() => handleUserUpdate(row.id)}
                    user={row}
                    generateTemporaryLink={(expiration, user) => {
                      const mutatedUser = user
                      delete mutatedUser.user_tags

                      generateTemporaryLink(expiration, mutatedUser)
                    }}
                  />
                }
              />
            )
          }

          return null
        },
      })

      if (userFilter === 'deleted' || userFilter === 'all') {
        actions.push({
          label: 'Restore User',
          trigger: (row) => {
            return (
              row.deleted && (
                <Button
                  compact
                  basic
                  icon
                  type="button"
                  className="svg-button"
                  onClick={() => restoreUser(row.id, row.organization_id)}
                  data-testid={`restore-user-${row.id}`}
                  key={`restore-user-${row.id}`}
                >
                  <IconReload />
                </Button>
              )
            )
          },
        })
      }
    }

    // Reset Password
    if (isAdmin) {
      actions.push({
        label: 'Generate Password',
        trigger: (row) => {
          if (!row.deleted) {
            return (
              <ButtonAndFormModal
                {...commonActionProps}
                modalId={`users/add-password-${row.id}`}
                modalProps={{ size: 'small' }}
                modalTitle={`${row.password ? 'Reset' : 'Add'} Password`}
                icon={row.password ? <IconLockSquareRoundedFilled /> : <IconLockOpen />}
                form={
                  <PasswordForm
                    user={row}
                    updateUser={handleUserUpdate}
                    reportPassphrase={(badPassphrase) => reportPassphrase(badPassphrase)}
                    generatePassphrase={(updatePassphrase) => generatePassphrase(updatePassphrase)}
                  />
                }
              />
            )
          }

          return null
        },
      })

      // Playbook Management
      if (editUsers) {
        actions.push({
          label: 'Playbook Management',
          trigger: (row) => {
            const message = `Are you sure you want to 
                ${row.edit_config ? 'revoke' : 'grant'} 
                Playbook Editor access to ${row.username}?`

            if (!row.deleted) {
              return (
                row.organization_id !== 1 && (
                  <ButtonAndFormModal
                    {...commonActionProps}
                    modalId={`users/playbook-management-${row.id}`}
                    modalTitle={`${row.edit_config ? 'Revoke' : 'Grant'} Playbook Management`}
                    icon={
                      <IconClipboard
                        className={classNames({ 'icon-svg-filled': row.edit_config })}
                      />
                    }
                    form={
                      <ConfirmForm
                        bodyText={message}
                        handleSubmit={() => updateUserAccess(row, 'edit_config')}
                        primaryButtonText="Confirm"
                      />
                    }
                  />
                )
              )
            }

            return null
          },
        })

        // User Management
        actions.push({
          label: 'User Management',
          trigger: (row) => {
            const isCurrentUser = currentUserEmail === row.username
            const message = `Are you sure you want to ${
              row.edit_users ? 'revoke' : 'grant'
            } user management access to ${row.username}?`

            if (!row.deleted) {
              return (
                !isCurrentUser &&
                row.organization_id !== 1 && (
                  <ButtonAndFormModal
                    {...commonActionProps}
                    modalId={`users/user-management-${row.id}`}
                    modalTitle={`${row.edit_users ? 'Revoke' : 'Grant'} User Management`}
                    icon={
                      row.edit_users ? (
                        <IconUserMinus className="icon-svg-filled" />
                      ) : (
                        <IconUserPlus />
                      )
                    }
                    form={
                      <ConfirmForm
                        bodyText={message}
                        handleSubmit={() => updateUserAccess(row, 'edit_users')}
                        primaryButtonText="Confirm"
                      />
                    }
                  />
                )
              )
            }

            return null
          },
        })

        // Real Time QA Access
        actions.push({
          label: 'QA Management',
          trigger: (row) => {
            const message = `Are you sure you want to 
                ${row.edit_qa ? 'revoke' : 'grant'} 
                QA access to ${row.username}?`

            if (!row.deleted) {
              return (
                qaEnabled &&
                row.organization_id !== 1 && (
                  <ButtonAndFormModal
                    {...commonActionProps}
                    modalId={`users/qa-management-${row.id}`}
                    modalTitle={`${row.edit_qa ? 'Revoke' : 'Grant'} QA Access`}
                    icon={row.edit_qa ? <IconMessageCircle2Filled /> : <IconMessageCircle2 />}
                    form={
                      <ConfirmForm
                        bodyText={message}
                        handleSubmit={() => updateUserAccess(row, 'edit_qa')}
                        modalClose={() => {}}
                        primaryButtonText="Confirm"
                      />
                    }
                  />
                )
              )
            }

            return null
          },
        })

        // QA Copilot Access
        actions.push({
          label: 'QA Copilot Management',
          trigger: (row) => {
            const message = `Are you sure you want to 
                ${row.edit_qa_copilot ? 'revoke' : 'grant'} 
                QA Copilot access to ${row.username}?`

            if (!row.deleted) {
              return (
                qaCopilotEnabled &&
                row.organization_id !== 1 && (
                  <ButtonAndFormModal
                    {...commonActionProps}
                    modalTitle={`${row.edit_qa_copilot ? 'Revoke' : 'Grant'} QA Copilot Access`}
                    modalId={`users/qa-copilot-management-${row.id}`}
                    icon={row.edit_qa_copilot ? <IconCircleCheckFilled /> : <IconCheck />}
                    form={
                      <ConfirmForm
                        bodyText={message}
                        handleSubmit={() => updateUserAccess(row, 'edit_qa_copilot')}
                        primaryButtonText="Confirm"
                      />
                    }
                  />
                )
              )
            }

            return null
          },
        })
      }
    }

    return actions
  }

  const rows = useMemo(() => {
    return users.map((user) => {
      const userWithTags = buildTagsForUser(user)

      return {
        ...userWithTags,
        full_name: `${user.first_name} ${user.last_name}`,
        playbook: findPlaybookForUser(user),
        uncategorized_tags: buildUncategorizedTagsForUser(user),
      }
    })
  }, [users])

  columns = columns.concat(tagCategoryColumns)

  const actions = buildActionsForUser()

  return (
    <>
      {rows && (
        <Segment className="not-padded" data-testid="user-table">
          <AdvancedTable
            loading={loading}
            rows={rows}
            columns={columns}
            actions={actions}
            defaultOrderBy="last_name"
            pagination
            stickyHeader
            stickyAction
          />
        </Segment>
      )}
    </>
  )
}

export default UserTable
