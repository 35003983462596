import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Segment, Loader } from 'semantic-ui-react'
import { isEmpty } from 'lodash'
import { useLocation } from 'react-router-dom'

import {
  COACHING_INBOX,
  COMPLIANCE_INBOX,
  COMPLETED,
  EXPIRED,
  IGNORED,
  INBOX,
  READY_FOR_REVIEW,
} from '@/reducers/tasks/tasks.constants'
import { fetchTasks, patchTask } from '@/reducers/tasks/tasks.actions'
import { FilterTypeSelect } from '@/components/filters/types/FilterTypeSelect'
import { AdvancedTablePagination } from '@/components/tables/AdvancedTable/AdvancedTablePagination'
import { usePagination } from '@/utils/hooks/usePagination'

import { InboxAccordion } from './components/InboxAccordion'
import { TaskCard } from './components/TaskCard'
import { TaskDrawer } from './drawers/TaskDrawer'
import { taskTypeOptions, TODO, ARCHIVE } from './inbox.helpers'

export const Inbox = ({ inboxType, title }) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const { loading, tasks } = useSelector((state) => state.tasks)
  const [taskStatus, setTaskStatus] = useState('')
  const [expandedSections, setExpandedSections] = useState([TODO])

  const todoTasks = tasks
    .filter((task) => task.status === READY_FOR_REVIEW)
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
  const archivedTasks = tasks
    .filter((task) => [IGNORED, EXPIRED, COMPLETED].includes(task.status))
    .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
  const filteredArchivedTasks = archivedTasks.filter((task) =>
    taskStatus ? task.status === taskStatus : true
  )

  const todoPagination = usePagination(todoTasks, 5)

  const handleToggleSection = (section) => {
    if (expandedSections.includes(section)) {
      setExpandedSections(expandedSections.filter((expanded) => expanded !== section))
    } else {
      setExpandedSections([...expandedSections, section])
    }
  }

  const handleUpdateTask = (taskId, status, handleClose) => {
    dispatch(patchTask(taskId, { status }, inboxType))

    if (handleClose) {
      handleClose()
    }
  }

  const getTaskDrawer = (task, isArchived = false) => {
    return (
      <TaskDrawer
        commentsEnabled={[COACHING_INBOX, COMPLIANCE_INBOX].includes(inboxType)}
        partialTask={task}
        primaryActionButtonText={isArchived ? 'Reopen' : 'Mark as Reviewed'}
        handlePrimaryAction={(taskId, handleClose) => {
          if (isArchived) {
            handleUpdateTask(taskId, READY_FOR_REVIEW, handleClose)
          } else {
            handleUpdateTask(taskId, COMPLETED, handleClose)
          }
        }}
        secondaryActionButtonText={isArchived ? null : 'Ignore'}
        handleSecondaryAction={
          isArchived
            ? null
            : (taskId, handleClose) => {
                handleUpdateTask(taskId, IGNORED, handleClose)
              }
        }
      />
    )
  }

  useEffect(() => {
    dispatch(fetchTasks(inboxType))
  }, [])

  return (
    <div>
      <header className="page-header">
        <h1 data-testid="inbox-title">{title}</h1>
      </header>

      {loading[INBOX] ? (
        <Loader active />
      ) : (
        <div>
          <InboxAccordion
            title="To Do"
            id={TODO}
            expanded={expandedSections.includes(TODO)}
            handleToggle={handleToggleSection}
          >
            {isEmpty(todoTasks) ? (
              <Segment>No tasks at this time.</Segment>
            ) : (
              <div className="inbox-tasks">
                {todoPagination.paginatedArray.map((task) => (
                  <TaskCard
                    key={task.id}
                    title={task.name}
                    taskType={task.type}
                    handleSecondaryAction={() => handleUpdateTask(task.id, IGNORED)}
                    location={location}
                    {...task}
                  >
                    {getTaskDrawer(task)}
                  </TaskCard>
                ))}
                <AdvancedTablePagination
                  activePage={todoPagination.activePage}
                  rowsPerPage={todoPagination.rowsPerPage}
                  count={todoPagination.count}
                  totalPages={todoPagination.totalPages}
                  setActivePage={todoPagination.setActivePage}
                  transparent
                />
              </div>
            )}
          </InboxAccordion>

          <InboxAccordion
            title="Archive"
            id={ARCHIVE}
            expanded={expandedSections.includes(ARCHIVE)}
            handleToggle={handleToggleSection}
            muted
          >
            <FilterTypeSelect
              autoFocus={false}
              showAllOnEmpty
              schema={{
                accessor: 'organizationId',
                label: 'Archived Tasks',
                options: taskTypeOptions,
              }}
              value={taskStatus}
              updateSearch={(accessor, value) => {
                setTaskStatus(value)
              }}
            />

            {isEmpty(filteredArchivedTasks) ? (
              <Segment>No tasks match the filter.</Segment>
            ) : (
              <div className="inbox-tasks">
                {filteredArchivedTasks.map((task) => (
                  <TaskCard
                    key={task.id}
                    title={task.name}
                    taskType={task.type}
                    handleSecondaryAction={() => handleUpdateTask(task.id, READY_FOR_REVIEW)}
                    secondaryActionButtonText="Reopen"
                    primaryActionButtonText="View Details"
                    location={location}
                    {...task}
                  >
                    {getTaskDrawer(task, true)}
                  </TaskCard>
                ))}
              </div>
            )}
          </InboxAccordion>
        </div>
      )}
    </div>
  )
}
