import React, { useEffect, useLayoutEffect, useRef, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { withLDConsumer } from 'launchdarkly-react-client-sdk'
import { isEmpty } from 'lodash'

import { unauthenticateUser } from '@/reducers/auth/currentUser.actions'
import { setSidebarCollapsedSections } from '@/reducers/ui/ui.redux'

import { SIDEBAR_COLLAPSED_SECTIONS } from './Sidebar.constants'
import { getSidebarData } from './Sidebar.helpers'
import { SidebarHeader } from './SidebarHeader'
import { SidebarSection } from './SidebarSection'
import { SidebarFooter } from './SidebarFooter'

import './Sidebar.scss'

export const SidebarComponent = ({ flags = {}, ldClient }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const currentLinkRef = useRef(null)
  const { currentOrganization, currentUser, customBranding } = useSelector((state) => state)
  const { sidebarCollapsedSections } = useSelector((state) => state.ui)
  const { userOrgHierarchy } = useSelector((state) => state.orgHierarchy)
  const sidebarData = useMemo(
    () => getSidebarData(currentOrganization, currentUser, flags),
    [currentOrganization, currentUser, flags]
  )
  // Filter out any section entirely that has no content
  const sidebarSections = Object.entries(sidebarData).filter(
    // eslint-disable-next-line no-unused-vars
    ([section, { links = [] }]) => !isEmpty(links)
  )

  const handleToggleCollapseSection = (section) => {
    let updatedSections = sidebarCollapsedSections

    if (sidebarCollapsedSections.includes(section)) {
      updatedSections = sidebarCollapsedSections.filter((collapsed) => collapsed !== section)
    } else {
      updatedSections = [...sidebarCollapsedSections, section]
    }

    dispatch(setSidebarCollapsedSections(updatedSections))
    localStorage.setItem(SIDEBAR_COLLAPSED_SECTIONS, JSON.stringify(updatedSections))
  }

  const handleLogout = (event) => {
    event.preventDefault()

    history.push('/login')

    // if the user is prompted to confirm and cancels, we don't want to clear
    if (history.location.pathname === '/login') {
      dispatch(unauthenticateUser({ history, location, ldClient }))
    }
  }

  useLayoutEffect(() => {
    if (currentLinkRef.current) {
      currentLinkRef.current.scrollIntoView({ block: 'center' })
    }
  }, [])

  useEffect(() => {
    const savedSidebarCollapsedSections = JSON.parse(
      localStorage.getItem(SIDEBAR_COLLAPSED_SECTIONS)
    )

    if (savedSidebarCollapsedSections) {
      dispatch(setSidebarCollapsedSections(savedSidebarCollapsedSections))
    }
  }, [])

  return (
    <aside className="balto-sidebar">
      <SidebarHeader branding={customBranding} organizationName={currentOrganization.name} />
      <div className="balto-sidebar__sections">
        {sidebarSections.map(([section, { title, links = [], noCollapse = false }]) => (
          <SidebarSection
            key={section}
            section={section}
            title={title}
            links={links}
            noCollapse={noCollapse}
            collapsedSections={sidebarCollapsedSections}
            handleToggleCollapseSection={handleToggleCollapseSection}
            currentLinkRef={currentLinkRef}
            currentPath={location.pathname}
          />
        ))}
      </div>
      <SidebarFooter
        currentUser={currentUser}
        userOrgHierarchy={userOrgHierarchy}
        handleLogout={handleLogout}
      />
    </aside>
  )
}

export const Sidebar = withLDConsumer()(SidebarComponent)
