export const COACHING_TASK_TYPE = 'coaching'
export const COMPLIANCE_TASK_TYPE = 'compliance'
export const QA_DISPUTE_TASK_TYPE = 'qa_dispute'
export const QA_MANUAL_SCORE_TASK_TYPE = 'qa_manual_score'

export const READY_FOR_REVIEW = 'ready_for_review'
export const COMPLETED = 'completed'
export const EXPIRED = 'expired'
export const IGNORED = 'ignored'

export const INBOX = 'inbox'
export const COACHING_INBOX = [COACHING_TASK_TYPE]
export const COMPLIANCE_INBOX = [COMPLIANCE_TASK_TYPE]
export const QA_INBOX = [QA_DISPUTE_TASK_TYPE, QA_MANUAL_SCORE_TASK_TYPE]
