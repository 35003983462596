import React from 'react'
import format from 'date-fns/format'

import { Pill } from '@/components/pills/Pill'
import { truncatedColumn } from '@/components/helpers/tableColumnHelpers'
import { formatDuration } from '@/utils/helpers'

import { AudioStatus } from './components/AudioStatus'

export const DEFAULT_CALL_COLUMNS = [
  {
    label: 'Agent',
    accessor: 'agent_name',
    isSearchable: true,
    searchSchema: {
      type: 'multiSelect',
      label: 'Agents',
    },
  },
  {
    label: 'VOIP Call ID',
    accessor: 'voip_call_id',
    isSearchable: true,
  },
  {
    label: 'VOIP Customer ID',
    accessor: 'voip_customer_id',
    isSearchable: true,
    is_hidden: true,
  },
  {
    label: 'VOIP Campaign Name',
    accessor: 'voip_campaign_name',
    isSearchable: true,
    is_hidden: true,
  },
  {
    label: 'Disposition',
    accessor: 'disposition',
    isSearchable: true,
    is_hidden: true,
  },
  {
    label: 'Date Time',
    accessor: 'start_time',
    format: (dateInSeconds) => format(new Date(dateInSeconds || 0), "MMM d',' yyyy h:mm aa"),
  },
  {
    label: 'Playbook',
    accessor: 'playbook_name',
    isSearchable: true,
    searchSchema: {
      type: 'multiSelect',
      label: 'Playbooks',
    },
    format: (playbook) => truncatedColumn(playbook),
  },
  {
    label: 'Duration',
    accessor: 'duration',
    format: (duration) => formatDuration(duration),
  },
  {
    label: 'Win',
    accessor: 'is_win',
    format: (isWin) => (
      <div className="win-column-entry">
        <Pill small success={isWin} critical={!isWin}>
          {isWin ? 'Win' : 'Non-Win'}
        </Pill>
      </div>
    ),
  },
  {
    label: 'Audio Status',
    accessor: 'audio_status',
    is_hidden: true,
    format: (audioStatus) => <AudioStatus audioStatus={audioStatus || 'not_available'} />,
  },
  { label: 'AI Notes', accessor: 'call_summary', isParent: true, pillLabel: 'AI' },
  { label: 'Scores', accessor: 'scores', isParent: true },
]
